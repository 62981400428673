import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useState, useEffect } from 'react';
import { setCurrencyValue } from '../../utils/functions';
import { primaryTheme } from '../themes/themes';
import { ThemeProvider } from '@mui/material/styles';

export default function BaseCurrency({
    value,
    label,
    required,
    disabled,
    error,
    className,
    style,
    variant,
    readOnly,
    color,
    nullable,
    onInputChange,
    onBlur
}) {

    const [val, setVal] = useState(value != null ? setCurrencyValue(value) : "");

    useEffect(() => {
        setVal(value != null ? setCurrencyValue(value) : "")
    }, [value]);

    return (
        <ThemeProvider theme={primaryTheme}>
            <TextField
                className={className ?? ""}
                style={style ?? null}
                label={label}
                color='primary'
                error={error ? true : false}
                required={required}
                autoComplete='off'
                size="small"
                helperText={error || ' '}
                variant={variant ?? "standard"}
                InputProps={{
                    startAdornment:
                        <InputAdornment
                            position="start">
                            {<AttachMoneyIcon fontSize='small' />}
                        </InputAdornment>,
                    readOnly: readOnly ?? false,
                    style: { color: color }
                }}
                value={val !== null ? val : ""}
                onChange={(event) => {
                    const currencyValue = setCurrencyValue(event.target.value, nullable ? 1 : 0);
                    if (currencyValue !== null && currencyValue !== "") {
                        setVal(currencyValue)
                        if (onInputChange) {
                            onInputChange(currencyValue);
                        }
                    } else {
                        if (currencyValue === "0") {
                            setVal(currencyValue);
                            if (onInputChange) {
                                onInputChange(currencyValue);
                            }
                        } else {
                            setVal(null);
                            if (onInputChange) {
                                onInputChange(null);
                            }
                        }
                    }
                }}
                onBlur={() => { if (onBlur) { onBlur() } }}
                FormHelperTextProps={{
                    style: { height: '12px' },
                }}
                disabled={disabled}
            />
        </ThemeProvider>
    );
}