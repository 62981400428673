import { Box } from "@mui/material";
import BaseSlider from "../../BaseComponents/BaseSlider";
import { getSellerRebateMax } from "../../../utils/functions";
import {
    DOWN_PAYMENT_LABEL,
    LINK_LABEL,
    LOAN_LINK_LABEL,
    OFFER_PRICE_LABEL,
    RESET_ALL_LABEL,
    SAVE_CHANGES_LABEL,
    SELLER_REBATE_LABEL,
    SIGN_UP_LABEL
} from "../../../i18n/i18nLabel";
import {
    GET_CONVENTIONAL_DOWN_PAYMENT,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_LAST_SEARCH_RESULTS,
    GET_MLO_PROFILE,
    GET_OFFER_PRICE_CONVENTIONAL,
    GET_PROFILE_ROLE,
    GET_SELLER_REBATE_CONVENTIONAL
} from "../../../constants/store/getters";
import { useStore } from "../../../hooks/store/store";
import { useState } from "react";
import {
    BORROWER_ROLE,
    CONVENTIONAL,
    GUEST_ROLE,
    MIN_CONVENTIONAL,
} from "../../../constants/constants";
import { useTranslation } from 'react-i18next';
import BasePercent from "../../BaseComponents/BasePercent";
import BaseBtn from "../../BaseComponents/BaseBtn";
import SellerInfo from "./SellerInfo";
import { CREATE_ACCOUNT_ROUTE } from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import AddLoanOriginator from "../AddLoanOriginator";

export default function Conventional(props) {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const history = useHistory();

    const [price, setPrice] = useState((state[GET_OFFER_PRICE_CONVENTIONAL] * 100));

    const [showAddLoanOriginator, setShowAddLoanOriginator] = useState(false);

    const minPrice = -30;

    const maxPrice = 30;

    const [sellerValue, setSellerValue] = useState((state[GET_SELLER_REBATE_CONVENTIONAL] * 100));

    const currentDownPayment = Number((state[GET_CONVENTIONAL_DOWN_PAYMENT] * 100).toFixed(2));

    const [downPayment, setDownPayment] = useState(currentDownPayment);

    const [sellerRange, setSellerRange] = useState(getSellerRebateMax(
        state[GET_INVESTMENT_PROFILE_LOAN] ?? { loanType: CONVENTIONAL, occupancyType: null },
        downPayment)
    );

    const sellerRangeLabels = () => {
        const labels = [];
        for (let i = 0; i <= sellerRange; i++) {
            labels.push({
                value: i,
                label: `${i}%`
            });
        }
        return labels;
    }

    const resetAll = () => {
        setPrice(0);
        setSellerValue(0);
        setDownPayment(
            state[GET_INVESTMENT_PROFILE_LOAN]
                ? Number(state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc.toFixed(2))
                : Number((MIN_CONVENTIONAL * 100).toFixed(2))
        );
        setSellerRange(
            getSellerRebateMax(
                state[GET_INVESTMENT_PROFILE_LOAN] ?? { loanType: CONVENTIONAL, occupancyType: null },
                state[GET_INVESTMENT_PROFILE_LOAN]
                    ? Number(state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc.toFixed(2))
                    : Number((MIN_CONVENTIONAL * 100).toFixed(2))
            )
        )
    }

    const saveChanges = () => {
        localStorage.setItem("search_pro_down_payment", true);
        localStorage.setItem("offer_price_conventional", (price / 100));
        localStorage.setItem("seller_rebate_conventional", (sellerValue / 100));
        localStorage.setItem("down_payment_conventional", (downPayment / 100));
        localStorage.setItem("last_search_results", []);
        dispatch({ action: GET_OFFER_PRICE_CONVENTIONAL, value: (price / 100) });
        dispatch({ action: GET_SELLER_REBATE_CONVENTIONAL, value: (sellerValue / 100) });
        dispatch({ action: GET_CONVENTIONAL_DOWN_PAYMENT, value: (downPayment / 100) });
        dispatch({ action: GET_LAST_SEARCH_RESULTS, value: [] });
        if (props.onClose) {
            props.onClose();
        }
    }

    return (
        <Box
            sx={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    marginBottom: "10px"
                }}
            >
                <BaseBtn
                    label={t(RESET_ALL_LABEL)}
                    onClick={() => { resetAll(); }}
                    variant="outlined"
                />
            </Box>
            <Box sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
            }}>
                <label id="searchProOfferPrice">{t(OFFER_PRICE_LABEL)}: </label>
                &nbsp;
                &nbsp;
                <Box sx={{ width: "120px" }}>
                    <BasePercent
                        value={price}
                        onInputChange={(value) => {
                            setPrice(value);
                        }}
                        onBlur={() => {
                            if (price > maxPrice) {
                                setPrice(maxPrice);
                            }
                            if (price < minPrice) {
                                setPrice(minPrice);
                            }
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
                <BaseSlider
                    min={minPrice}
                    max={maxPrice}
                    step={0.5}
                    value={price}
                    onInputChange={(value) => setPrice(value)}
                />
            </Box>
            <Box sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
            }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <label id="searchProSellerRebate">{t(SELLER_REBATE_LABEL)}: </label>
                    &nbsp;
                    <SellerInfo />
                </Box>
                &nbsp;
                &nbsp;
                <Box sx={{ width: "120px" }}>
                    <BasePercent
                        value={sellerValue}
                        onInputChange={(value) => {
                            setSellerValue(value);
                        }}
                        onBlur={() => {
                            if (sellerValue > sellerRangeLabels().length - 1) {
                                setSellerValue(sellerRangeLabels().length - 1);
                            } else {
                                if (sellerValue < 0) {
                                    setSellerValue(0);
                                } else {
                                    setSellerValue(sellerValue);
                                }
                            }
                        }}
                        disabled={!state[GET_INVESTMENT_PROFILE_LOAN]}
                    />
                </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
                <BaseSlider
                    step={0.5}
                    value={sellerValue}
                    marks={sellerRangeLabels()}
                    onInputChange={(value) => {
                        setSellerValue(value);
                    }}
                    disabled={!state[GET_INVESTMENT_PROFILE_LOAN]}
                />
            </Box>
            <Box sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                marginTop: "10px"
            }}>
                <label id="searchProDownPayment">{t(DOWN_PAYMENT_LABEL)}:</label>
                &nbsp;
                &nbsp;
                <Box sx={{ width: "120px" }}>
                    <BasePercent
                        value={downPayment}
                        onInputChange={(value) => {
                            setDownPayment(value);
                            setSellerRange(getSellerRebateMax(
                                state[GET_INVESTMENT_PROFILE_LOAN] ?? { loanType: CONVENTIONAL, occupancyType: null },
                                value)
                            )
                        }}
                        onBlur={() => {
                            if (downPayment > 80) {
                                setDownPayment(80);
                            }
                            if (downPayment < Number(state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc.toFixed(2))) {
                                setDownPayment(Number(state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc.toFixed(2)));
                            }
                            if (sellerValue > sellerRange) {
                                setSellerValue(sellerRange)
                            }
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
                <BaseSlider
                    value={downPayment}
                    min={state[GET_INVESTMENT_PROFILE_LOAN]
                        ? Number(state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc.toFixed(2))
                        : Number((MIN_CONVENTIONAL * 100).toFixed(2))}
                    max={80}
                    step={5}
                    onInputChange={(value) => {
                        setDownPayment(value);
                        setSellerRange(getSellerRebateMax(
                            state[GET_INVESTMENT_PROFILE_LOAN] ?? { loanType: CONVENTIONAL, occupancyType: null },
                            value))
                        if (sellerValue > sellerRange) {
                            setSellerValue(sellerRange)
                        }
                    }}
                />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                {
                    (state[GET_PROFILE_ROLE] === BORROWER_ROLE && (!state[GET_INVESTMENT_PROFILE_LOAN] && !state[GET_MLO_PROFILE]))
                        ? <BaseBtn
                            label={t(LOAN_LINK_LABEL) + t(LINK_LABEL)}
                            onClick={() => { setShowAddLoanOriginator(true); }}
                            style={{ marginRight: "8px" }}
                        >
                        </BaseBtn>
                        : null
                }
                {
                    state[GET_PROFILE_ROLE] === GUEST_ROLE || !state[GET_PROFILE_ROLE]
                        ? < BaseBtn
                            label={t(SIGN_UP_LABEL)}
                            onClick={() => {
                                history.push(CREATE_ACCOUNT_ROUTE);
                            }}
                            style={{ marginRight: "8px" }}
                        />
                        : null
                }
                <BaseBtn
                    label={t(SAVE_CHANGES_LABEL)}
                    onClick={() => { saveChanges(); }}
                />
            </Box>
            <AddLoanOriginator
                open={showAddLoanOriginator}
                onClose={() => {
                    setShowAddLoanOriginator(false);
                }}
            />
        </Box>
    );
}