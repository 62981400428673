import {
    CONVENTIONAL,
    DEFAULT_CONVENTIONAL,
    DEFAULT_FHA,
    FARMERS_HOME_ADMINISTRATION,
    FHA,
    GEOLOCATION_NOT_SUPPORTED,
    GEO_PERMISSION_DENIED,
    GEO_POSITION_UNAVAILABLE,
    GEO_TIMEOUT,
    LOAN_DEFAULT_INITIAL_VALUES,
    LOAN_EDITOR_MODES,
    PRIMARY_RESIDENCE,
    UNKNOWN_ERROR,
    VA
} from "../constants/constants";
import { LANDING_PAGE } from "../constants/routes";
import validator from "validator";
import {
    BIGGER_THAN_ZERO_LABEL,
    ENTER_VALID_EMAIL_LABEL,
    ENTER_VALID_PHONE_NUMBER_LABEL,
    ENTER_VALID_STATE_LABEL,
    ENTER_VALID_ZIP_CODE_LABEL,
    MAXIMUM_LABEL,
    MINIMUM_LABEL,
    THIS_FIELD_ONLY_NUMBERS_LABEL,
    THIS_FIELD_ONLY_TEXTS_LABEL,
} from "../i18n/i18nLabel";

export function setCurrencyValue(value, nullable) {
    if (value) {
        value = value.toString();
        value = value.replace(/[^\d]/g, '');
        if (value === '') {
            return nullable ? null : '0';
        }
        value = value.replace(/^0+/, '');
        if (value === '') {
            return nullable ? null : '0';
        }
        let formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return formattedValue;
    } else {
        if (nullable) {
            return null;
        } else {
            return "0";
        }
    }
}

export function handleError(error) {
    if (error) {
        if (error?.request?.response) {
            if (error?.request?.response[0] === '"' || error?.request?.response[0] === '{') {
                if (JSON.parse(error?.request?.response)?.title === "Bad Request") {
                    return UNKNOWN_ERROR;
                }
            }
            return error.request.response;
        } else {
            return UNKNOWN_ERROR;
        }
    }
};

export function destroySession() {
    const openOfferProTrip = localStorage.getItem('open_offer_pro_trip');
    const openPropertyTrip = localStorage.getItem('open_property_trip');
    const openHomesTrip = localStorage.getItem('open_homes_trip');
    const openFavoritesTrip = localStorage.getItem('open_favorites_trip');
    const openProfileTrip = localStorage.getItem('open_profile_trip');
    const openPropertyValuesTrip = localStorage.getItem('open_property_values_trip');
    const openSearchProTrip = localStorage.getItem('open_search_pro_trip');

    localStorage.clear();

    if (openOfferProTrip) {
        localStorage.setItem('open_offer_pro_trip', openOfferProTrip);
    }

    if (openPropertyTrip) {
        localStorage.setItem('open_property_trip', openPropertyTrip);
    }

    if (openHomesTrip) {
        localStorage.setItem('open_homes_trip', openHomesTrip);
    }

    if (openFavoritesTrip) {
        localStorage.setItem('open_favorites_trip', openFavoritesTrip);
    }

    if (openProfileTrip) {
        localStorage.setItem('open_profile_trip', openProfileTrip);
    }

    if (openPropertyValuesTrip) {
        localStorage.setItem('open_property_values_trip', openPropertyValuesTrip);
    }

    if (openSearchProTrip) {
        localStorage.setItem('open_search_pro_trip', openSearchProTrip);
    }
}

export const getSellerRebateMax = (loan, downPayment) => {
    const { loanType, occupancyType } = loan || {};

    if (!loanType) {
        return 0;
    }

    switch (loanType) {
        case CONVENTIONAL:
            if (downPayment < 10) {
                return 3;
            }
            if (downPayment < 25) {
                return 6;
            }
            return 9;
        case FHA:
            return 6;
        case VA:
            if (occupancyType === PRIMARY_RESIDENCE) {
                return 4
            }
            break;
        case FARMERS_HOME_ADMINISTRATION:
            if (occupancyType === PRIMARY_RESIDENCE) {
                return 6;
            }
            break;
        default:
            break;
    }

    return 0;
}

export default function getSharedPropertyUrl(id) {
    if (id) {
        if (window.location.origin.startsWith("https://qa")) {
            return `https://qa.padzilly.app/homes/details/${id}`;
        }
        if (
            window.location.origin.startsWith("https://www.") ||
            window.location.origin.startsWith("https://padzilly.com")
        ) {
            return `https://padzilly.com/homes/details/${id}`;
        }
        if (window.location.origin.startsWith("https://localhost")) {
            return `https://qa.padzilly.app/homes/details/${id}`;
        }
        if (window.location.origin.startsWith("https://1")) {
            return `https://qa.padzilly.app/homes/details/${id}`;
        }
    }
    return "";
}

export async function getCurrentLocation() {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    resolve(showPosition(position));
                },
                error => {
                    reject(showError(error));
                }
            );
        } else {
            reject(GEOLOCATION_NOT_SUPPORTED);
        }
    });
}

function showPosition(position) {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;
    return { lat: lat, lng: lon };
}

function showError(error) {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            return GEO_PERMISSION_DENIED;
        case error.POSITION_UNAVAILABLE:
            return GEO_POSITION_UNAVAILABLE;
        case error.TIMEOUT:
            return GEO_TIMEOUT;
        case error.UNKNOWN_ERROR:
            return UNKNOWN_ERROR;
    }
}

export function getDownPayment(lastSearch, dowpayment) {
    if (dowpayment) {
        return (dowpayment);
    }
    if (lastSearch?.loanType === CONVENTIONAL) {
        return DEFAULT_CONVENTIONAL * 100;
    } else {
        return DEFAULT_FHA * 100;
    }
}

export function getLenderURL() {
    if (window.location.origin.startsWith("https://qa")) {
        return `https://qa.padzilly.app/lenders`;
    }
    if (
        window.location.origin.startsWith("https://www.") ||
        window.location.origin.startsWith("https://padzilly.com")
    ) {
        return `https://padzilly.com/lenders`;
    }
    if (window.location.origin.startsWith("https://localhost")) {
        return `https://qa.padzilly.app/lenders`;
    }
    if (window.location.origin.startsWith("https://1")) {
        return `https://qa.padzilly.app/lenders`;
    }
    return LANDING_PAGE;
}

export function getBoolean(value) {
    if (value === "true") {
        return true;
    }
    if (value === "false") {
        return false;
    }
    if (value) {
        return true;
    } else {
        return false;
    }
}

export function getStateCode(stateName) {
    const states = {
        "Alabama": "AL",
        "Alaska": "AK",
        "Arizona": "AZ",
        "Arkansas": "AR",
        "California": "CA",
        "Colorado": "CO",
        "Connecticut": "CT",
        "Delaware": "DE",
        "Florida": "FL",
        "Georgia": "GA",
        "Hawaii": "HI",
        "Idaho": "ID",
        "Illinois": "IL",
        "Indiana": "IN",
        "Iowa": "IA",
        "Kansas": "KS",
        "Kentucky": "KY",
        "Louisiana": "LA",
        "Maine": "ME",
        "Maryland": "MD",
        "Massachusetts": "MA",
        "Michigan": "MI",
        "Minnesota": "MN",
        "Mississippi": "MS",
        "Missouri": "MO",
        "Montana": "MT",
        "Nebraska": "NE",
        "Nevada": "NV",
        "New Hampshire": "NH",
        "New Jersey": "NJ",
        "New Mexico": "NM",
        "New York": "NY",
        "North Carolina": "NC",
        "North Dakota": "ND",
        "Ohio": "OH",
        "Oklahoma": "OK",
        "Oregon": "OR",
        "Pennsylvania": "PA",
        "Rhode Island": "RI",
        "South Carolina": "SC",
        "South Dakota": "SD",
        "Tennessee": "TN",
        "Texas": "TX",
        "Utah": "UT",
        "Vermont": "VT",
        "Virginia": "VA",
        "Washington": "WA",
        "West Virginia": "WV",
        "Wisconsin": "WI",
        "Wyoming": "WY"
    };

    const stateCode = states[stateName];
    if (stateCode) {
        return stateCode;
    } else {
        return stateName;
    }
}

///LOAN FORM (CODE FROM PARTNER)

function setNewDictionary(propertiesDictionary, loanDocumentParsed) {
    Object.entries(loanDocumentParsed).map(([key, value]) => {
        propertiesDictionary[key] = value;
    });
}

function getNameItems(value) {
    let lastSpaceIndex = value.lastIndexOf(" ");
    let firstName = value.substring(0, lastSpaceIndex);
    let lastName = value.substring(lastSpaceIndex + 1);
    return { firstName: firstName, lastName: lastName };
}

export function getParserResults(results, buyerData, mode) {
    const textInputValues = [
        "borrowerFullName",
        "coBorrowerFullName",
        "borrowerMobilePhone",
        "borrowerEmail",
        "city",
        "state",
        "zipCode",
        "streetAddress",
        "unitNo",
    ];
    let propertiesDictionary = {};
    setNewDictionary(propertiesDictionary, results);
    let tempLoan = { ...LOAN_DEFAULT_INITIAL_VALUES };
    Object.keys(propertiesDictionary).map((key) => {
        if (tempLoan.hasOwnProperty(key)) {
            let values = propertiesDictionary[key];
            if (values && values.length > 0) {
                let index =
                    (key === "coBorrowerFullName" || key === "coBorrowerMthIncome") &&
                        values.length > 1
                        ? 1
                        : 0;
                tempLoan[key] = textInputValues.includes(key)
                    ? values[index]
                    : parseFloat(values[index].replace(/,/g, ""));
            }
        }
    });

    if (mode != LOAN_EDITOR_MODES.CREATE_LOAN) {
        tempLoan.borrowerFirstName = buyerData.borrowerFirstName;
        tempLoan.borrowerLastName = buyerData.borrowerLastName;
        tempLoan.borrowerFullName =
            buyerData.borrowerFirstName + " " + buyerData.borrowerLastName;
        tempLoan.borrowerEmail = buyerData.borrowerEmail;
        tempLoan.borrowerMobilePhone = buyerData.borrowerMobilePhone;
        tempLoan.borrowerId = buyerData.borrowerId;
    }
    if (
        !!tempLoan.borrowerFullName &&
        (!tempLoan.borrowerFirstName || !tempLoan.borrowerLastName)
    ) {
        const borrowerNameItems = getNameItems(tempLoan.borrowerFullName);
        tempLoan.borrowerFirstName = borrowerNameItems.firstName;
        tempLoan.borrowerLastName = borrowerNameItems.lastName;
    }
    if (
        !!tempLoan.coBorrowerFullName &&
        (!tempLoan.coBorrowerFirstName || !tempLoan.coBorrowerLastName)
    ) {
        const borrowerNameItems = getNameItems(tempLoan.coBorrowerFullName);
        tempLoan.coBorrowerFirstName = borrowerNameItems.firstName;
        tempLoan.coBorrowerLastName = borrowerNameItems.lastName;
    }
    return { tempLoan, propertiesDictionary };

}

export const validateInitialLoanValues = (loanData) => {
    const errorsList = {};
    Object.keys(LOAN_VALIDATIONS).forEach((key) => {
        const value = loanData[key];
        if (value != undefined) {
            const error = formValidation(key, value, LOAN_VALIDATIONS) || "";
            if (error !== "") {
                errorsList[key] = error;
            }
        }
    });
    return errorsList;
};

export const LOAN_VALIDATIONS = {
    borrowerFirstName: {
        error: "",
        validate: "text",
    },
    borrowerLastName: {
        error: "",
        validate: "text",
    },
    borrowerEmail: {
        error: "",
        validate: "email",
    },
    borrowerMobilePhone: {
        error: "",
        validate: "phone",
    },
    city: {
        error: "",
        validate: "text",
    },
    state: {
        error: "",
        validate: "state",
    },
    zipCode: {
        error: "",
        validate: "zipCode",
    },
    coBorrowerFirstName: {
        error: "",
        validate: "text",
    },
    coBorrowerLastName: {
        error: "",
        validate: "text",
    },
    realtorFirstName: {
        error: "",
        validate: "text",
    },
    realtorLastName: {
        error: "",
        validate: "text",
    },
    realtorEmail: {
        error: "",
        validate: "email",
    },
    realtorMobilePhone: {
        error: "",
        validate: "phone",
    },
    totalCashInBank: {
        error: "",
        validate: "biggerThanZero",
    },
    noteRate: {
        error: "",
        validate: "biggerThanZero",
    },
    loanAmount: {
        error: "",
        validate: "biggerThanZero",
    },
    prepaidInterestDays: {
        error: "",
        validate: "biggerThanZero",
    },
    oneTimePMI: {
        error: "",
        validate: "biggerThanZero",
    },
    lenderTitleIns: {
        error: "",
        validate: "biggerThanZero",
    },
    term: {
        error: "",
        validate: "biggerThanZero",
    },
    totalHousingExpenses: {
        error: "",
        validate: "biggerThanZero",
    },
    purchasePrice: {
        error: "",
        validate: "biggerThanZero",
    },
    term: {
        error: "",
        validate: "biggerThan120",
    }
};

export function formValidation(name, value, schema) {
    const { validate } = schema[name];
    let error = "";

    if (!validate) return error;

    if (
        (validate != "biggerThanZero" && value != "") ||
        validate == "biggerThanZero"
    ) {
        switch (validate) {
            case "text":
                if (!/^[A-Za-z\s]+$/.test(value) || value?.length > 150) {
                    error = THIS_FIELD_ONLY_TEXTS_LABEL;
                }
                break;

            case "number":
                if (!validator.isNumeric(value)) {
                    error = THIS_FIELD_ONLY_NUMBERS_LABEL;
                }
                break;

            case "email":
                if (!validator.isEmail(value) || value?.length > 150) {
                    error = ENTER_VALID_EMAIL_LABEL;
                }
                break;

            case "phone":
                if (!validator.isMobilePhone(value, "en-US")) {
                    error = ENTER_VALID_PHONE_NUMBER_LABEL;
                }
                break;

            case "biggerThanZero":
                if (value <= 0) {
                    error = BIGGER_THAN_ZERO_LABEL;
                }
                break;

            case "biggerThan120":
                if (value < 120) {
                    error = BIGGER_THAN_ZERO_LABEL;
                }
                break;

            case "zipCode":
                if (!validator.isNumeric(value)) {
                    error = THIS_FIELD_ONLY_NUMBERS_LABEL;
                }
                if (value?.length != 5) {
                    error = ENTER_VALID_ZIP_CODE_LABEL;
                }
                break;

            case "state":
                if (!validator.isAlpha(value)) {
                    error = THIS_FIELD_ONLY_TEXTS_LABEL;
                }
                if (value?.length != 2) {
                    error = ENTER_VALID_STATE_LABEL;
                }
                break;

            default:
                break;
        }
    }

    return error;
}

export function addressValidFormat(value) {
    return typeof value === 'string' && value.trim() !== '';
}

export function formatPhoneNumber(number) {
    const regexWithCountryCode = /^\+1(\d{10})$/;
    const regexTenDigits = /^([2-9]\d{9})$/;

    if (regexWithCountryCode.test(number)) {
        const digits = number.slice(2);
        return `+1(${digits.slice(0, 3)})-${digits.slice(3, 6)}-${digits.slice(6)}`;
    } else if (regexTenDigits.test(number)) {

        return `(${number.slice(0, 3)})-${number.slice(3, 6)}-${number.slice(6)}`;
    }
    return number;
}

export function formatAddress(address, unit) {
    if (!unit) {
        return address;
    }

    const unitRegex = new RegExp(`\\b${unit}\\b(?!.*\\b${unit}\\b)`, 'i');
    const startWithUnit = new RegExp(`^${unit}\\b`, 'i');

    if (!startWithUnit.test(address)) {
        return address.replace(unitRegex, '').trim();
    }

    return address;
}