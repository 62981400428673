import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../constants/constants';

let theme = createTheme({
  palette: {}
});

export const secondaryTheme = createTheme(theme, {
  palette: {
    secondary: theme.palette.augmentColor({
      color: {
        main: grey[700],
      },
      name: 'secondary',
    }),
  },
});

export const primaryTheme = createTheme(theme, {
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: PRIMARY_COLOR,
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: SECONDARY_COLOR,
      },
      name: 'secondary',
    }),
  },
});

export const lightTheme = {
  bodyBackgroundColor: '#FFF',
  bodyTextColor: '#000',
  scrollBehavior: "smooth",
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
};
