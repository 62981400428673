import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import es from "./i18n/es/es";
import en from "./i18n/en/en";

i18next.use(initReactI18next).use(LanguageDetector).init({
    fallbackLng: "en-US",
    resources: {
        "en-US": {
            translation: en
        },
        "es-US": {
            translation: es
        }
    }
});

export default i18next;