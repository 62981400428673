import {
  ANNUAL_TAX_AMOUNT_LABEL,
  BEDROOMS_LABEL,
  CITY_LABEL,
  DOWN_PAYMENT_LABEL,
  FULL_BATH_LABEL,
  HALF_BATHROOMS_LABEL,
  HOME_TYPE_LABEL,
  INVESTMENT_LABEL,
  LISTING_PRICE_LABEL,
  LOT_SIZE_LABEL,
  PROP_PAYMENT_LABEL,
  SAVED_OFFER_PRICE_LABEL,
  SELLER_REBATE_LABEL,
  SQUARE_FEET_LABEL,
  STATE_LABEL,
  STATUS_LABEL,
  STREET_ADDRESS_LABEL,
  YEAR_BUILT_LABEL,
  ZIPCODE_LABEL
} from "../i18n/i18nLabel";

export const GOOGLE_MAPS = "AIzaSyCnI1x008vUhzpJKhHyqdERg3EJXHlEEQ4";
export const PUBLIC_STRIPE_KEY = "pk_test_51PjPmIFIUV8MwuoSwbbaah5w7xO178ADkRFMRYYINtjne27DnqJTq4iOzwhjFngwoLRAMJ5249s3kAvSjQaOdFYJ007to5zNzF";

//FILTERS
export const CONVENTIONAL = "Conventional";
export const FHA = "FHA";
export const VA = "VA";
export const PAYMENT = "Payment";
export const CASH = "Cash";
export const CONV = "Conv";
export const PRIMARY_RESIDENCE = 'PrimaryResidence';
export const FARMERS_HOME_ADMINISTRATION = "FarmersHomeAdministration";

export const TOWNHOUSE = "Townhouse";
export const CONDOMINIUM = "Condominium";
export const SINGLE_FAMILY = "Single Family";
export const MOBILE_HOME = "Mobile Home";

//SEARCH
export const GET_NEW_VALUES_FOR_SEARCH = (loan) => {

  const lastSearch = localStorage.getItem("last_search")
    ? JSON.parse(localStorage.getItem("last_search"))
    : {};

  return {
    downpayment: (loan) ? loan?.downPaymentPerc / 100 : 0.05,
    loanType: (loan) ? loan?.loanType : lastSearch?.loanType ?? CONVENTIONAL,
    filterQualified: lastSearch?.filterQualified ?? 0,
    pageSize: 30,
    search: lastSearch?.search ?? null,
    searchBy: lastSearch?.searchBy ?? null,
    searchState: lastSearch?.searchState ?? null,
    propertyType:
      lastSearch?.propertyType ??
      "Condominium,Townhouse,Single Family,Mobile Home",
    minPrice: lastSearch?.minPrice ?? null,
    maxPrice: lastSearch?.maxPrice ?? null,
    minBedrooms: lastSearch?.minBedrooms ?? null,
    maxBedrooms: lastSearch?.maxBedrooms ?? null,
    minBathrooms: lastSearch?.minBathrooms ?? null,
    maxBathrooms: lastSearch?.maxBathrooms ?? null,
    minLotSize: lastSearch?.minLotSize ?? null,
    maxLotSize: lastSearch?.maxLotSize ?? null,
    minHomeYear: lastSearch?.minHomeYear ?? null,
    maxHomeYear: lastSearch?.maxHomeYear ?? null,
    minHomeSize: lastSearch?.minHomeSize ?? null,
    maxHomeSize: lastSearch?.maxHomeSize ?? null,
    propertyId: null,
    lastPropertyId: null,
    lastModificationTimestamp: null,
    sellerRebate: (loan?.loanType === CONVENTIONAL || lastSearch?.loanType === CONVENTIONAL)
      ? Number(localStorage.getItem("seller_rebate_conventional"))
      : Number(localStorage.getItem("seller_rebate_fha")),
    offerPrice: (loan?.loanType === CONVENTIONAL || lastSearch?.loanType === CONVENTIONAL)
      ? Number(localStorage.getItem("offer_price_conventional"))
      : Number(localStorage.getItem("offer_price_fha")),
  };
};

//OTHERS//
export const NO_PROPERTY = "No Property";
export const NO_ACTIVE_SESSION = "No Active Session";
export const ASC = "ASC";
export const DESC = "DESC";
export const PROPERTY_SRC = "images/property-placeholder.jpg";
export const LOGO = "images/padzilly.png";
export const MARKER = "images/marker.svg";
export const INFO_EMAIL = "info@approvaliq.com";

//NAVIGATION
export const HOMES = "Homes";
export const FAVORITES = "Favorites";
export const PROFILE = "Profile";
export const SETTINGS = "Settings";

//ROLES
export const GUEST_ROLE = "Guest";
export const BORROWER_ROLE = "Borrower";

//LOAN
export const APPROVED = "Approved";
export const NOT_APPROVED = "Not Approved";

//MESSAGE TYPES
export const SUCCESS = "success";
export const ERROR = "error";
export const INFO = "info";

//MASK
export const PHONE_MASK = ['(', /\d/, /\d/, /\d/, ')', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

//TABLE FIEDS
export const TABLE_FIELDS = [
  STREET_ADDRESS_LABEL,
  STATUS_LABEL,
  CITY_LABEL,
  STATE_LABEL,
  ZIPCODE_LABEL,
  LISTING_PRICE_LABEL,
  SAVED_OFFER_PRICE_LABEL,
  SELLER_REBATE_LABEL,
  DOWN_PAYMENT_LABEL,
  ANNUAL_TAX_AMOUNT_LABEL,
  'HOA',
  PROP_PAYMENT_LABEL,
  INVESTMENT_LABEL,
  BEDROOMS_LABEL,
  FULL_BATH_LABEL,
  HALF_BATHROOMS_LABEL,
  SQUARE_FEET_LABEL,
  HOME_TYPE_LABEL,
  YEAR_BUILT_LABEL,
  LOT_SIZE_LABEL,
];

//STATUS
export const STATUS_PENDING = "Pending";
export const STATUS_UNDER_CONTRACT = "Active Under Contract";
export const STATUS_COMING_SOON = "Coming Soon";
export const STATUS_ACTIVE = "Active";

//OFFER PRO
export const CALCULATION_OFFER_PRO_VALUES = {
  bipClosingFactor: 0.036,
  floodFactor: 0.0042,
  pmiFactor: 0.00075,
  ufmipFactor: 0.0175
};

//SHARE
export const SUBJECT = "Shared Padzilly Property";
export const VISITING = "You can access this property by visiting: ";

//IMPORT
export const REALTOR_URL_TO_IMPORT = "realtor.com/realestateandhomes-detail/";
export const ZILLOW_URL_TO_IMPORT = "zillow.com/homedetails/";
export const REALTOR = "realtor";
export const ZILLOW = "zillow";

//LOCATION
export const GEOLOCATION_NOT_SUPPORTED = "Geolocation is not supported by this browser";
export const GEO_PERMISSION_DENIED = "User denied the request for Geolocation";
export const GEO_POSITION_UNAVAILABLE = "Location information is unavailable";
export const GEO_TIMEOUT = "The request to get user location timed out";

//ERROR
export const UNKNOWN_ERROR = "Something went wrong, please try again later";

//DOWNPAYMENT
export const DEFAULT_CONVENTIONAL = 0.05;
export const MIN_CONVENTIONAL = 0.03;
export const DEFAULT_FHA = 0.035;
export const MIN_FHA = 0.035;
export const MAX_DOWN_PAYMENT = 0.8;

//PAGES
export const LANDING_PAGE = "landing_page";
export const HOMES_PAGE = "homes";
export const PROPERTY_DETAIL_PAGE = "property_detail";
export const LOGIN_ACCOUNT = "/login-account";
export const CREATE_ACCOUNT = "/create-account";

//UTILS
export const PRIMARY_COLOR = "#233C90";
export const SECONDARY_COLOR = "#EB5A2D";
export const TITLES_COLOR = "black";//#333333eb
export const GOOGLE_PLAY = "images/GooglePlay.svg";
export const APP_STORE = "images/App_Store_Badge.svg";
export const EN_US = "en-US";
export const ES_US = "es-US";
export const LIST = "list";
export const MAP = "map";

//UPLOAD LOAN
export const LOAN_DEFAULT_INITIAL_VALUES = {
  noteRate: 0.0,
  loanAmount: 0.0,
  originationFeePerc: 0.0,
  originationFeeAmt: 0.0,
  brokerFeePerc: 0.0,
  brokerFeeAmt: 0.0,
  lenderCreditPerc: 0.0,
  lenderCreditAmt: 0.0,
  discountPointsPerc: 0.0,
  discountPointsAmt: 0.0,
  prepaidInterestDays: 0.0,
  oneTimePMI: 0.0,
  mipOrVAFee: 0.0,
  mtgInsReserveMths: 0.0,
  homeownerInsMo: 0.0,
  hoiReserveMths: 0.0,
  propTaxReserveMths: 0.0,
  floodInsMo: 0.0,
  floodInsReserveMths: 0,
  otherInsMo: 0.0,
  otherInsReserveMths: 0,
  lenderTitleIns: 0.0,
  mtgstamps: 0.0,
  deedstamps: 0.0,
  totalAMTPaidToOthers: 0.0,
  term: 0,
  mgtInsMonthly: 0.0,
  totalHousingExpenses: 0.0,
  purchasePrice: 0.0,
  otherCreditsAmt: 0.0,
  otherCreditsPerc: 0.0,
  loanType: "",
  ltv: 0.0,
  downPaymentPerc: 0.0,
  insMtgInsPeriodFactor1: 0.0,
  allNonVariableFees: 0.0,
  borrowerFirstName: "",
  borrowerLastName: "",
  borrowerFullName: "",
  borrowerEmail: "",
  borrowerMobilePhone: "",
  borrowerId: "",
  borrowerMthIncome: 0.0,
  coBorrowerFirstName: "",
  coBorrowerLastName: "",
  coBorrowerFullName: "",
  coBorrowerMthIncome: 0.0,
  addtlCoBorrowerIncome: 0.0,
  totalCashInBank: 0.0,
  realtorFirstName: "",
  realtorLastName: "",
  realtorFullName: "",
  realtorEmail: "",
  realtorMobilePhone: "",
  realtorId: "",
  vaFirstUse: true,
  prepaidFinanceCharges: 0.0,
  propTaxMo: 0.0,
  streetAddress: "",
  unitNo: "",
  city: "",
  state: "",
  zipCode: "",
};

export const LOAN_EDITOR_MODES = {
  CREATE_LOAN: "CREATE-NEW",
  ADD_LOAN: "ADD-NEW",
  EDIT_LOAN: "EDIT-LOAN",
};

export const BUYER_INFO = {
  borrowerFirstName: "",
  borrowerLastName: "",
  borrowerFullName: "",
  borrowerEmail: "",
  borrowerMobilePhone: "",
  borrowerId: "",
  borrowerMthIncome: 0,
  coBorrowerFirstName: "",
  coBorrowerLastName: "",
  coBorrowerFullName: "",
  coBorrowerMthIncome: 0,
  addtlCoBorrowerIncome: 0,
  totalCashInBank: 0,
  realtorFirstName: "",
  realtorLastName: "",
  realtorFullName: "",
  realtorEmail: "",
  realtorMobilePhone: "",
  streetAddress: "",
  unitNo: "",
  city: "",
  state: "",
  zipCode: "",
  loanId: ""
}

export const CALCULATION_DATA = {
  noteRate: 0,
  loanAmount: 0,
  originationFeePerc: 0,
  originationFeeAmt: 0,
  brokerFeePerc: 0,
  brokerFeeAmt: 0,
  lenderCreditPerc: 0,
  lenderCreditAmt: 0,
  discountPointsPerc: 0,
  discountPointsAmt: 0,
  prepaidInterestDays: 0,
  oneTimePMI: 0,
  mipOrVAFee: 0,
  mtgInsReserveMths: 0,
  homeownerInsMo: 0,
  hoiReserveMths: 0,
  propTaxReserveMths: 0,
  floodInsMo: 0,
  floodInsReserveMths: 0,
  otherInsMo: 0,
  otherInsReserveMths: 0,
  lenderTitleIns: 0,
  mtgstamps: 0,
  deedstamps: 0,
  totalAMTPaidToOthers: 0,
  term: 0,
  mgtInsMonthly: 0,
  totalHousingExpenses: 0,
  purchasePrice: 0,
  otherCreditsAmt: 0,
  otherCreditsPerc: 0,
  loanType: "",
  ltv: 0,
  downPaymentPerc: 0,
  insMtgInsPeriodFactor1: 0,
  allNonVariableFees: 0,
  vaFirstUse: false,
  prepaidFinanceCharges: 0,
  propTaxMo: 0,
};