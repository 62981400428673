import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { primaryTheme, secondaryTheme } from '../themes/themes';
import { useTranslation } from 'react-i18next';
import { CANCEL_LABEL } from '../../i18n/i18nLabel';

export default function BaseBtn({
    close,
    label,
    loading,
    disabled,
    id,
    className,
    variant,
    onClick,
    color,
    style,
    children,
    sx
}) {

    const { t } = useTranslation();

    return (
        (!close) ?
            <ThemeProvider theme={primaryTheme}>
                <Button
                    sx={sx}
                    id={id}
                    className={className ?? ""}
                    variant={variant ?? "contained"}
                    onClick={(event) => onClick(event)}
                    disabled={disabled || loading}
                    color={color ?? 'primary'}
                    style={{
                        borderRadius: style?.borderRadius ?? "3px",
                        fontSize: ".65rem",
                        fontWeight: "bold",
                        ...style
                    }}
                >
                    {
                        <Box sx={{ display: "flex", justifyContent: "center", textAlign: 'center' }}>
                            <div style={{ color: loading ? "transparent" : "inherit" }}>
                                {label ?? children}
                            </div>
                            <CircularProgress style={{
                                color: !loading ? "transparent" : "inherit",
                                position: "absolute"
                            }}
                                size={20}
                            />
                        </Box>
                    }
                </Button>
            </ThemeProvider>
            : <ThemeProvider theme={secondaryTheme}>
                <Button
                    id={id}
                    disabled={disabled}
                    variant="outlined"
                    color='secondary'
                    style={{ borderRadius: "3px", fontSize: ".65rem", fontWeight: "bold" }}
                    onClick={() => onClick()}
                >
                    {label ? label : t(CANCEL_LABEL)}
                </Button>
            </ThemeProvider>
    );
}