import Slider from '@mui/material/Slider';
import { useEffect, useState } from 'react';
import { primaryTheme } from '../themes/themes';
import { ThemeProvider } from '@mui/material/styles';

export default function BaseSlider({ value, max, min, step, marks, onInputChange, disabled }) {

    const [val, setVal] = useState(value ?? 0);

    useEffect(() => {
        if (value || value === 0) {
            setVal(value);
        }
    }, [value]);

    function valuetext(valuetext) {
        return valuetext;
    }

    return (
        <ThemeProvider theme={primaryTheme}>
            <Slider
                max={max ?? marks?.length - 1 ?? 100}
                min={min ?? 0}
                size="small"
                color='primary'
                step={step ?? 1}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                marks={marks}
                value={val}
                defaultValue={0}
                onChange={(event) => {
                    setVal(event.target.value);
                    if (event.target.value || event.target.value === 0) {
                        setVal(event.target.value);
                    }
                    if (onInputChange) {
                        onInputChange(event.target.value);
                    }
                }}
                disabled={disabled}
            />
        </ThemeProvider>
    );
}