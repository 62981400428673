import React from 'react';
import { Helmet } from 'react-helmet';

export default function MetaTags({ metas }) {

    const title = metas.filter((item) => item.title);

    const openGraphAndTwitterCard = metas.filter((item) => !item.title);

    return (
        <Helmet>
            <title>{title.length ? title[0].title : "::Padzilly::"}</title>
            {
                openGraphAndTwitterCard.map((meta, index) => (
                    <meta key={index} property={`og:${meta.type}`} content={meta.content} />
                ))
            }
            {
                openGraphAndTwitterCard.map((meta, index) => (
                    <meta key={index} name={`twitter:${meta.type}`} content={meta.content} />
                ))
            }
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="google-adsense-account" content="ca-pub-8686996611787044" />
            <meta name="keywords" content="homes, property, buy a home, real state, property for sale, townhouse, condominium, single home, mobile home" />
            <link rel="canonical" href="https://padzilly.com/" />
            <meta name="robots" content="index, follow" />
        </Helmet>
    );
};