import axios from 'axios';
import {
    GET_NEAR_PROPERTIES,
    LANDING_PAGE_ELEMENTS
} from '../endpoints/endpoints';

async function getIPAddress() {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
    } catch (error) {
        throw error;
    }
}

export async function getLocationByIP() {
    try {
        const ip = await getIPAddress();
        if (!ip) return null;
        if (ip !== localStorage.getItem("ip_address")) {
            //`https://ipinfo.io/${ip}/json/`
            const response = await axios.get(`https://api.techniknews.net/ipgeo/${ip}`);
            localStorage.setItem("ip_address", ip);
            localStorage.setItem("address_info", JSON.stringify(response.data));
            return response.data;
        }
        return JSON.parse(localStorage.getItem("address_info"));
    } catch (error) {
        throw error;
    }
}

export async function getNearProperties(city, state) {
    try {
        if (city && state) {
            const result = await axios.get(GET_NEAR_PROPERTIES + "?city=" + city + "&state=" + state);
            return result.data;
        }
        return [];
    } catch (error) {
        throw error;
    }
}

export async function getLandingPageElements() {
    try {
        const result = await axios.get(LANDING_PAGE_ELEMENTS);
        return result.data?.length === 0 ? ["NO ELEMENTS"] : result.data;
    } catch (error) {
        throw error;
    }
}