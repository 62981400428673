//DISCLAIMER_DIALOG
export const DISCLAIMER_TITLE_TEXT = "DISCLAIMER_TITLE_TEXT";
export const DISCLAIMER_TITLE_LINK_TEXT = "DISCLAIMER_TITLE_LINK_TEXT";
export const PAYMENT_BREAKDOWN_TITLE_TEXT = "PAYMENT_BREAKDOWN_TITLE_TEXT";
export const PAYMENT_BREAKDOWN_FIRST_TEXT = "PAYMENT_BREAKDOWN_FIRST_TEXT";
export const PAYMENT_BREAKDOWN_SECOND_TEXT = "PAYMENT_BREAKDOWN_SECOND_TEXT";
export const PAYMENT_BREAKDOWN_SECOND_STRONG_TEXT = "PAYMENT_BREAKDOWN_SECOND_STRONG_TEXT";
export const TAXES_BREAKDOWN_PAYMENT_OFFER_PRO_TEXT = "TAXES_BREAKDOWN_PAYMENT_OFFER_PRO_TEXT";
//IMPORT PROP
export const IMPORT_PROP_ONE_TEXT = "IMPORT_PROP_ONE_TEXT";
export const IMPORT_PROP_TWO_TEXT = "IMPORT_PROP_TWO_TEXT";
export const IMPORT_PROP_O_TEXT = "IMPORT_PROP_O_TEXT";
//PROFILE
export const THERE_IS_NO_LOAN_O_TEXT = "THERE_IS_NO_LOAN_O_TEXT";
export const CURRENTLY_AS_GUEST_TEXT = "CURRENTLY_AS_GUEST_TEXT";
export const PLEASE_AUTHENTICATE_TEXT = "PLEASE_AUTHENTICATE_TEXT";
export const AMOUNTS_BELOW_TEXT = "AMOUNTS_BELOW_TEXT";
export const REMOVE_LOAN_ORIGINATOR_TEXT = "REMOVE_LOAN_ORIGINATOR_TEXT";
export const DO_NOT_HAVE_LOAN_ORIGINATOR_TEXT = "DO_NOT_HAVE_LOAN_ORIGINATOR_TEXT";
export const LOAN_OFFICER_CONNECTED_TEXT = "LOAN_OFFICER_CONNECTED_TEXT";
export const WE_ARE_CONTACTING_TEXT = "WE_ARE_CONTACTING_TEXT";
export const NO_INVESTMENT_PROFILE_TEXT = "NO_INVESTMENT_PROFILE_TEXT";
export const CONTINUE_AS_GUEST_TEXT = "CONTINUE_AS_GUEST_TEXT";
export const INVESTMENT_PROFILE_NO_LOAN_TEXT = "INVESTMENT_PROFILE_NO_LOAN_TEXT";
export const SEARCH_PRO_TEXT = "SEARCH_PRO_TEXT";
export const LENDER_NOT_ACTIVE_TEXT = "LENDER_NOT_ACTIVE_TEXT";
export const SUSCRIBE_TEXT = "SUSCRIBE_TEXT";
export const SUBSCRIPTION_DISCLAIMER_TEXT = "SUBSCRIPTION_DISCLAIMER_TEXT";
export const MLO_NOT_ACTIVE_TEXT = "MLO_NOT_ACTIVE_TEXT";
//LOADING PROP
export const GETTING_PROPS_TEXT = "GETTING_PROPS_TEXT";
export const GETTING_DETAILS_TEXT = "GETTING_DETAILS_TEXT";
//FORGOT PASS
export const FORGOT_PASS_OTP_TEXT = "FORGOT_PASS_OTP_TEXT";
//VERIFY
export const VERIFICATION_TEXT = "VERIFICATION_TEXT";
export const CHECK_SPAM_TEXT = "CHECK_SPAM_TEXT";
//SELECT LOAN TYPE
export const LETS_CREATE_PADZILLY_SWIFTMATCH_TEXT = "LETS_CREATE_PADZILLY_SWIFTMATCH_TEXT";
export const CHOOSE_PRE_APPROVED_TEXT = "CHOOSE_PRE_APPROVED_TEXT";
export const CHOOSE_NOT_APPROVED_TEXT = "CHOOSE_NOT_APPROVED_TEXT";
//INV PROFILE
export const CREATE_SWIFTMATCH_PROFILE_TEXT = "CREATE_SWIFTMATCH_PROFILE_TEXT";
export const FOR_BEST_RESULTS_TEXT = "FOR_BEST_RESULTS_TEXT";
export const IN_THE_MEANTIME_TEXT = "IN_THE_MEANTIME_TEXT";
export const WE_WILL_REPLACE_TEXT = "WE_WILL_REPLACE_TEXT";
//LOAN OFFICER
export const WE_WILL_CHECK_LOAN_OFFICER_TEXT = "WE_WILL_CHECK_LOAN_OFFICER_TEXT";
export const GREAT_NEWS_TEXT = "GREAT_NEWS_TEXT";
export const LOAN_OFFICER_NOT_CONNECTED_TEXT = "LOAN_OFFICER_NOT_CONNECTED_TEXT";
export const WE_ARE_REACHING_OUT_TEXT = "WE_ARE_REACHING_OUT_TEXT";
export const GREAT_NEWS_TWO_TEXT = "GREAT_NEWS_TWO_TEXT";
export const CONTINUE_SEARCH_TEXT = "CONTINUE_SEARCH_TEXT";
//NO PROPERTIES
export const ENTER_INFO_FOR_SEARCH_TEXT = "ENTER_INFO_FOR_SEARCH_TEXT";
export const NO_MATCHING_PROPERTIES_TEXT = "NO_MATCHING_PROPERTIES_TEXT";
export const CHANGE_PROPERTY_TYPE_TEXT = "CHANGE_PROPERTY_TYPE_TEXT";
export const FIND_IN_ZILLOW_OR_REALTOR_TEXT = "FIND_IN_ZILLOW_OR_REALTOR_TEXT";
export const CHECK_MLS_TEXT = "CHECK_MLS_TEXT";
export const GOOD_NEWS_TEXT = "GOOD_NEWS_TEXT";
export const CHECK_MAP_TEXT = "CHECK_MAP_TEXT";
export const HAVE_COVERAGE_TEXT = "HAVE_COVERAGE_TEXT";
export const HAVE_NOT_COVERAGE_TEXT = "HAVE_NOT_COVERAGE_TEXT";
// ERROR GETTING PROPERTIES
export const PROBLEM_LOADING_PROPERTIES_TEXT = "PROBLEM_LOADING_PROPERTIES_TEXT";
export const CHECK_YOUR_CONNECTION_TEXT = "CHECK_YOUR_CONNECTION_TEXT";
//NO PROP DETAILS
export const WE_COULDNT_FIND_PROP_TEXT = "WE_COULDNT_FIND_PROP_TEXT";
//FAV
export const FAVORITES_GUEST_TEXT = "FAVORITES_GUEST_TEXT";
export const NOT_SAVED_PROPERTIES_TEXT = "NOT_SAVED_PROPERTIES_TEXT";
export const EXPLORE_AND_SAVE_PROPERTIES_TEXT = "EXPLORE_AND_SAVE_PROPERTIES_TEXT";
export const NO_SAVED_SEARCHES_TEXT = "NO_SAVED_SEARCHES_TEXT";
export const NO_SAVED_SEARCHES_SUBTITLE_TEXT = "NO_SAVED_SEARCHES_SUBTITLE_TEXT";
export const DELETE_SEARCH_TEXT = "DELETE_SEARCH_TEXT";
//OFFER PRO BACKDROP
export const OFFER_PRO_BACKDROP_TEXT = "OFFER_PRO_BACKDROP_TEXT";
//OFFER PRO
export const VERIFY_MONTHLY_ASSOCIATION_TEXT = "VERIFY_MONTHLY_ASSOCIATION_TEXT";
export const YOU_APPEAR_QUALIFIED_TEXT = "YOU_APPEAR_QUALIFIED_TEXT";
export const CASH_NEEDED_TEXT = "CASH_NEEDED_TEXT";
export const PAYMENT_NEEDED_TEXT = "PAYMENT_NEEDED_TEXT";
export const PAYMENT_AND_CASH_NEEDED_TEXT = "PAYMENT_AND_CASH_NEEDED_TEXT";
//MESSAGES
export const MUST_BE_GREATER_THAN_TEXT = "MUST_BE_GREATER_THAN_TEXT";
export const MORTGAGE_PAYMENT_ERROR_TEXT = "MORTGAGE_PAYMENT_ERROR_TEXT";
export const VALID_URL_TO_IMPORT_ERROR_TEXT = "VALID_URL_TO_IMPORT_ERROR_TEXT";
export const INVALID_EMAIL_ERROR_TEXT = "INVALID_EMAIL_ERROR_TEXT";
export const ONLY_LETTERS_ERROR_TEXT = "ONLY_LETTERS_ERROR_TEXT";
export const NO_MLO_FOUND_ERROR_TEXT = "NO_MLO_FOUND_ERROR_TEXT";
export const MLO_ALREADY_INVITED = "MLO_ALREADY_INVITED";
export const MLO_SAME_EMAIL_ERROR_TEXT = "MLO_SAME_EMAIL_ERROR_TEXT";
export const PASSWORDS_MUST_MATCH_ERROR_TEXT = "PASSWORDS_MUST_MATCH_ERROR_TEXT";
export const FIRST_NAME_ERROR_TEXT = "FIRST_NAME_ERROR_TEXT";
export const LAST_NAME_ERROR_TEXT = "LAST_NAME_ERROR_TEXT";
export const FULL_NAME_ERROR_TEXT = "FULL_NAME_ERROR_TEXT";
export const MLO_WAS_REMOVED_ERROR_TEXT = "MLO_WAS_REMOVED_ERROR_TEXT";
export const CANT_INVITE_PADZILLY_MLO_TEXT = "CANT_INVITE_PADZILLY_MLO_TEXT";
//LANDING PAGE
export const WE_DIDNT_FIND_PROPS_TEXT = "WE_DIDNT_FIND_PROPS_TEXT";
export const INFO_TEXT_ONE_TEXT = "INFO_TEXT_ONE_TEXT";
export const INFO_ONE_POINT_ONE_TEXT = "INFO_ONE_POINT_ONE_TEXT";
export const INFO_ONE_POINT_TWO_TEXT = "INFO_TWO_POINT_TWO_TEXT";
export const INFO_ONE_POINT_THREE_TEXT = "INFO_ONE_POINT_THREE_TEXT";
export const OFFER_LIKE_A_PRO_TEXT = "OFFER_LIKE_A_PRO_TEXT";
export const INFO_TWO_POINT_ONE_TEXT = "INFO_TWO_POINT_ONE_TEXT";
export const INFO_TWO_POINT_TWO_TEXT = "INFO_TWO_POINT_TWO_TEXT";
export const INFO_TWO_POINT_THREE_TEXT = "INFO_TWO_POINT_THREE_TEXT";
export const LOAN_APPROVAL_TEXT = "LOAN_APPROVAL_TEXT";
export const INFO_THREE_POINT_ONE_TEXT = "INFO_THREE_POINT_ONE_TEXT";
export const INFO_THREE_POINT_TWO_TEXT = "INFO_THREE_POINT_TWO_TEXT";
export const INFO_THREE_POINT_THREE_TEXT = "INFO_THREE_POINT_THREE_TEXT";
export const INFO_THREE_POINT_FOUR_TEXT = "INFO_THREE_POINT_FOUR_TEXT";
export const FOOTER_TEXT = "FOOTER_TEXT";
//SCHOOLS
export const SCHOOLS_DISCLAIMER_TEXT = "SCHOOLS_DISCLAIMER_TEXT";
//SEARCH PRO
export const SELLER_REBATE_ONLY_LAP_TEXT = "SELLER_REBATE_ONLY_LAP_TEXT";
//LOADING PROPERTIES
export const LOADING_PROPERTIES_ONE_TEXT = "LOADING_PROPERTIES_ONE_TEXT";
export const LOADING_PROPERTIES_TWO_TEXT = "LOADING_PROPERTIES_TWO_TEXT";
export const LOADING_PROPERTIES_THREE_TEXT = "LOADING_PROPERTIES_THREE_TEXT";
export const LOADING_PROPERTIES_FOUR_TEXT = "LOADING_PROPERTIES_FOUR_TEXT";
export const LOADING_PROPERTIES_FIVE_TEXT = "LOADING_PROPERTIES_FIVE_TEXT";
export const LOADING_PROPERTIES_SIX_TEXT = "LOADING_PROPERTIES_SIX_TEXT";
export const LOADING_PROPERTIES_SEVEN_TEXT = "LOADING_PROPERTIES_SEVEN_TEXT";
export const LOADING_PROPERTIES_EIGHT_TEXT = "LOADING_PROPERTIES_EIGHT_TEXT";
export const LOADING_PROPERTIES_NINE_TEXT = "LOADING_PROPERTIES_NINE_TEXT";
//TRIP
export const TRIP_HOME_SEARCH_GOALS_PAYMENT_TEXT = "TRIP_HOME_SEARCH_GOALS_PAYMENT_TEXT";
export const TRIP_HOME_SEARCH_GOALS_CASH_TEXT = "TRIP_HOME_SEARCH_GOALS_CASH_TEXT";
export const TRIP_PAYMENT_BTN_TEXT = "TRIP_PAYMENT_BTN_TEXT";
export const TRIP_CASH_BTN_TEXT = "TRIP_CASH_BTN_TEXT";
export const TRIP_PAYMENT_CASH_BTN_TEXT = "TRIP_PAYMENT_CASH_BTN_TEXT";
export const TRIP_CONVENTIONAL_LOAN_TEXT = "TRIP_CONVENTIONAL_LOAN_TEXT";
export const TRIP_FHA_LOAN_TEXT = "TRIP_FHA_LOAN_TEXT";
export const TRIP_SEARCH_PRO_TEXT = "TRIP_SEARCH_PRO_TEXT";
export const TRIP_BATH_TEXT = "TRIP_BATH_TEXT";
export const TRIP_BED_TEXT = "TRIP_BED_TEXT";
export const TRIP_SAVE_SEARCH_TEXT = "TRIP_SAVE_SEARCH_TEXT";
export const TRIP_FILTERS_TEXT = "TRIP_FILTERS_TEXT";
export const TRIP_PROPERTY_ESTIMATED_PAYMENT_TEXT = "TRIP_PROPERTY_ESTIMATED_PAYMENT_TEXT";
export const TRIP_PROPERTY_CASH_NEEDED_TEXT = "TRIP_PROPERTY_CASH_NEEDED_TEXT";
export const TRIP_MENU_FAVORITES_TEXT = "TRIP_MENU_FAVORITES_TEXT";
export const TRIP_MENU_PROFILE_TEXT = "TRIP_MENU_PROFILE_TEXT";
export const TRIP_INVESTMENT_PROFILE_TEXT = "TRIP_INVESTMENT_PROFILE_TEXT";
export const TRIP_IMPORT_PROPERTIES_TEXT = "TRIP_IMPORT_PROPERTIES_TEXT";
export const TRIP_COMPARE_PROPERTIES_TEXT = "TRIP_COMPARE_PROPERTIES_TEXT";
export const TRIP_OFFER_PRO_TEXT = "TRIP_OFFER_PRO_TEXT";
export const TRIP_SEARCH_PRO_LOANS_TEXT = "TRIP_SEARCH_PRO_LOANS_TEXT";
export const TRIP_SEARCH_PRO_OFFER_PRICE_TEXT = "TRIP_SEARCH_PRO_OFFER_PRICE_TEXT";
export const TRIP_SEARCH_PRO_SELLER_TEXT = "TRIP_SEARCH_PRO_SELLER_TEXT";
export const TRIP_SEARCH_PRO_SELLER_RED_TEXT = "TRIP_SEARCH_PRO_SELLER_RED_TEXT";
export const TRIP_SEARCH_PRO_DOWN_PAYMENT_TEXT = "TRIP_SEARCH_PRO_DOWN_PAYMENT_TEXT";
export const TRIP_LOAN_ORIGINATOR_TEXT = "TRIP_LOAN_ORIGINATOR_TEXT";
//ADD LOAN (BUYER)
export const TRUE_PDF_TEXT = "TRUE_PDF_TEXT";
export const IMAGE_PDF_TEXT = "IMAGE_PDF_TEXT";
export const WARNING_TEXT = "WARNING_TEXT";
export const LOAN_FORM_TEXT = "LOAN_FORM_TEXT";
export const COMPLETE_BYUER_INFO_TEXT = "COMPLETE_BYUER_INFO_TEXT";
export const COMPLETE_CALCULATIONS_INFO_TEXT = "COMPLETE_CALCULATIONS_INFO_TEXT";
