import { Box, Container } from "@mui/material";
import { Link } from "@mui/material";
import {
    ABOUT,
    APP_STORE,
    CONTACT_EN,
    CONTACT_ES,
    FACEBOOK_LINK,
    GOOGLE_PLAY,
    INSTAGRAM_LINK,
    INVESTORS,
    LINKEDIN_LINK,
    PRIVACY_POLICY,
    SUPPORT_ENGLISH,
    SUPPORT_ESPANOL,
    TERMS_CONDITIONS
} from "../../constants/urls";
import {
    GOOGLE_PLAY as GOOGLE_PLAY_IMG,
    APP_STORE as APP_STORE_IMG,
    TITLES_COLOR,
    INFO_EMAIL,
    PRIMARY_COLOR,
    ES_US
} from "../../constants/constants";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { FOOTER_TEXT } from "../../i18n/i18nText";
import {
    ABOUT_LABEL,
    ALL_RIGHTS_RESERVED_LABEL,
    CONTACT_LABEL,
    FOLLOW_US_LABEL,
    HOMEBUYING_EVOLVED_LABEL,
    IMPORTANT_LINKS_LABEL,
    INVESTORS_LABEL,
    PRIVACY_POLICY_LABEL,
    SUPPORT_LABEL,
    TERMS_OF_USE_LABEL
} from "../../i18n/i18nLabel";
import { useHistory } from "react-router-dom";
import { LANDING_PAGE } from "../../constants/routes";
import LogoNoWhite from "../Images/LogoNoWhite";
import { useStore } from "../../hooks/store/store";
import { GET_LANGUAGE } from "../../constants/store/getters";
import Facebook from "../../icons/Facebook";
import Instagram from "../../icons/Instagram";
import LinkedIn from "../../icons/LinkedIn";

export default function LandingPageFooter() {

    const history = useHistory();

    const { state } = useStore();

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                background: "#f9f9f9",
                paddingTop: "40px",
                paddingBottom: "80px",
            }}
        >
            <Container>
                <Box
                    sx={{
                        paddingBottom: "30px",
                        borderBottom: "1px solid #80808073",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }}
                >
                    <Box sx={{
                        display: "flex",
                        flexDirection: isSmOrSmaller ? "column" : "row",
                    }}
                    >
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: isSmOrSmaller ? "100%" : "33%",
                            paddingLeft: "20px",
                            alignItems: "start"
                        }}
                        >
                            <Box
                                sx={{ cursor: "pointer" }}
                                onClick={() => history.push(LANDING_PAGE)}
                            >
                                <LogoNoWhite width="175px" height="45px" />
                            </Box>
                            <p style={{ margin: "0px", marginTop: "8px" }}>
                                {t(HOMEBUYING_EVOLVED_LABEL)}
                            </p>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: isSmOrSmaller ? "100%" : "33%",
                            paddingLeft: "20px",
                            marginTop: isSmOrSmaller ? "20px" : "0px",
                            alignItems: "start"
                        }}
                        >
                            <p
                                style={{
                                    margin: "0px",
                                    fontWeight: "bold",
                                    fontSize: "1.3rem"
                                }}
                            >
                                {t(IMPORTANT_LINKS_LABEL)}
                            </p>
                            <Link
                                sx={{
                                    fontSize: "1.1rem",
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    color: TITLES_COLOR,
                                    marginBottom: "5px",
                                    marginTop: "5px",
                                    transition: "color 0.3s ease",
                                }}
                                onMouseEnter={(e) => (e.target.style.color = PRIMARY_COLOR)}
                                onMouseLeave={(e) => (e.target.style.color = "inherit")}
                                onClick={() => window.open(ABOUT, "_blank")}
                            >
                                {t(ABOUT_LABEL)}
                            </Link>
                            <Link
                                sx={{
                                    fontSize: "1.1rem",
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    color: TITLES_COLOR,
                                    marginBottom: "5px",
                                    marginTop: "5px",
                                    transition: "color 0.3s ease",
                                }}
                                onMouseEnter={(e) => (e.target.style.color = PRIMARY_COLOR)}
                                onMouseLeave={(e) => (e.target.style.color = "inherit")}
                                onClick={() => window.open(INVESTORS, "_blank")}
                            >
                                {t(INVESTORS_LABEL)}
                            </Link>
                            <Link
                                sx={{
                                    fontSize: "1.1rem",
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    color: TITLES_COLOR,
                                    marginBottom: "5px",
                                    marginTop: "5px",
                                    transition: "color 0.3s ease",
                                }}
                                onMouseEnter={(e) => (e.target.style.color = PRIMARY_COLOR)}
                                onMouseLeave={(e) => (e.target.style.color = "inherit")}
                                onClick={() => window.open(state[GET_LANGUAGE] === ES_US ? CONTACT_ES : CONTACT_EN, "_blank")}
                            >
                                {t(CONTACT_LABEL)}
                            </Link>
                            <Link
                                sx={{
                                    fontSize: "1.1rem",
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    color: TITLES_COLOR,
                                    marginBottom: "5px",
                                    marginTop: "5px",
                                    transition: "color 0.3s ease",
                                }}
                                onMouseEnter={(e) => (e.target.style.color = PRIMARY_COLOR)}
                                onMouseLeave={(e) => (e.target.style.color = "inherit")}
                                onClick={() => window.open(state[GET_LANGUAGE] === ES_US ? SUPPORT_ESPANOL : SUPPORT_ENGLISH, "_blank")}
                            >
                                {t(SUPPORT_LABEL)}
                            </Link>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: isSmOrSmaller ? "100%" : "33%",
                            paddingLeft: "20px",
                            marginTop: isSmOrSmaller ? "20px" : "0px",
                            alignItems: "start"
                        }}
                        >
                            <p
                                style={{
                                    margin: "0px",
                                    fontSize: "1.3rem",
                                }}
                            >
                                {t(CONTACT_LABEL)}
                            </p>
                            <a
                                style={{
                                    marginTop: "10px",
                                    cursor: "pointer",
                                    color: "black",
                                    textDecoration: "none",
                                    transition: "color 0.3s ease",
                                }}
                                onMouseEnter={(e) => (e.target.style.color = PRIMARY_COLOR)}
                                onMouseLeave={(e) => (e.target.style.color = "inherit")}
                                href={`mailto:${INFO_EMAIL}`}
                            >
                                {INFO_EMAIL}
                            </a>
                            <p style={{
                                fontWeight: "bold",
                                marginBottom: "5px"
                            }}>
                                {t(FOLLOW_US_LABEL)}
                            </p>
                            <Box>
                                <Link
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => window.open(FACEBOOK_LINK, "_blank")}
                                    style={{ marginRight: "10px" }}
                                >
                                    <Facebook />
                                </Link>
                                <Link
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => window.open(INSTAGRAM_LINK, "_blank")}
                                    style={{ marginRight: "10px" }}
                                >
                                    <Instagram />
                                </Link>
                                <Link
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => window.open(LINKEDIN_LINK, "_blank")}
                                >
                                    <LinkedIn />
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: isSmOrSmaller ? "column-reverse" : "row",
                        marginTop: "20px"
                    }}
                >
                    <Box
                        sx={{ width: isSmOrSmaller ? "100%" : "60%" }}
                    >
                        <Box sx={{ marginLeft: isSmOrSmaller ? "0px" : "20px" }}>
                            <p
                                style={{
                                    fontSize: ".7rem",
                                    margin: "0px",
                                    width: isSmOrSmaller ? "100%" : "50%",
                                    textAlign: isSmOrSmaller ? "center" : "start",
                                    marginTop: isSmOrSmaller ? "15px" : "0px"
                                }}
                            >
                                {t(FOOTER_TEXT)}
                            </p>
                            <Box sx={{
                                display: "flex",
                                marginTop: "5px",
                                marginBottom: "5px",
                                justifyContent: isSmOrSmaller ? "center" : "start"
                            }}>
                                <Box
                                    sx={{
                                        width: "110px",
                                        height: "auto",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => window.open(GOOGLE_PLAY, "_blank")}
                                >
                                    {
                                        <img alt="Google Play" src={GOOGLE_PLAY_IMG} width={"110px"} />
                                    }
                                </Box>
                                <Box
                                    sx={{
                                        width: "125px",
                                        height: "auto",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => window.open(APP_STORE, "_blank")}
                                >
                                    {
                                        <img
                                            alt="App Store"
                                            src={APP_STORE_IMG}
                                            width={"125px"}
                                            height={"29px"}
                                            style={{ display: "block", marginTop: "7px" }}
                                        />
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <p
                            style={{
                                margin: "0px",
                                marginLeft: isSmOrSmaller ? "0px" : "20px",
                                fontSize: ".8rem",
                                textAlign: isSmOrSmaller ? "center" : "start"

                            }}>
                            {t(ALL_RIGHTS_RESERVED_LABEL)}
                        </p>
                    </Box>
                    <Box
                        sx={{ width: isSmOrSmaller ? "100%" : "40%" }}
                    >
                        <Box sx={{
                            display: "flex",
                            justifyContent: isSmOrSmaller ? "center" : "start"
                        }}>
                            <p
                                style={{
                                    margin: "0px",
                                    cursor: "pointer",
                                    transition: "color 0.3s ease",
                                }}
                                onMouseEnter={(e) => (e.target.style.color = PRIMARY_COLOR)}
                                onMouseLeave={(e) => (e.target.style.color = "inherit")}
                                onClick={() => window.open(TERMS_CONDITIONS, "_blank")}
                            >
                                {t(TERMS_OF_USE_LABEL)}
                            </p>
                            &nbsp;
                            &nbsp;
                            <p
                                style={{
                                    margin: "0px",
                                    cursor: "pointer",
                                    transition: "color 0.3s ease",
                                }}
                                onMouseEnter={(e) => (e.target.style.color = PRIMARY_COLOR)}
                                onMouseLeave={(e) => (e.target.style.color = "inherit")}
                                onClick={() => window.open(PRIVACY_POLICY, "_blank")}
                            >
                                {t(PRIVACY_POLICY_LABEL)}
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}