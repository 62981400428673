import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import BaseDialogFooter from "../BaseDialog/BaseDialogFooter";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseBtn from "../BaseComponents/BaseBtn";
import BaseInput from "../BaseComponents/BaseInput";
import { Alert, Box } from "@mui/material";
import { useError } from "../../hooks/handleError";
import { formatPhoneNumber, handleError } from "../../utils/functions";
import BaseAlert from "../BaseComponents/BaseAlert";
import { validations } from "../../utils/validations";
import { useState, useRef } from "react";
import { useStore } from "../../hooks/store/store";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
    CANT_INVITE_PADZILLY_MLO,
    EMAIL_ERROR,
    FIRST_NAME_ERROR,
    MLO_ALREADY_INVITED_ERROR,
    NO_MLO_ERROR
} from "../../constants/messages";
import { getMloByEmail, inviteMLO } from "../../services/loanServices";
import {
    GET_FUNDS_LIMIT,
    GET_INVESTMENT_PROFILE,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_LANGUAGE,
    GET_MLO_ID,
    GET_MLO_PROFILE,
    GET_PAYMENT_LIMIT,
    GET_PROFILE_TOKEN
} from "../../constants/store/getters";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { updateInvestmentProfile } from "../../services/investmentProfileServices";
import { useTranslation } from 'react-i18next';
import {
    ADD_LOAN_ORIGINATOR_LABEL,
    CANCEL_LABEL,
    CHANGE_LOAN_ORIGINATOR_LABEL,
    CONFIRM_LABEL,
    CONFIRM_LOAN_ORIGINATOR_LABEL,
    CONTACT_LABEL,
    EMAIL_ERROR_LABEL,
    EMAIL_LABEL,
    LOAN_OFFICER_EMAIL_LABEL,
    LOAN_OFFICER_LABEL,
    LOAN_OFFICER_NAME_LABEL,
    LOAN_OFFICER_TWO_LABEL,
    NAME_ERROR_LABEL,
    PHONE_LABEL,
} from "../../i18n/i18nLabel";
import {
    DO_NOT_HAVE_LOAN_ORIGINATOR_TEXT,
    LOAN_OFFICER_CONNECTED_TEXT,
    MLO_NOT_ACTIVE_TEXT,
    WE_ARE_CONTACTING_TEXT
} from "../../i18n/i18nText";
import { ES_US, SECONDARY_COLOR } from "../../constants/constants";
import { CONTACT_EN, CONTACT_ES } from "../../constants/urls";

export default function AddLoanOriginator(props) {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const NAME = "name";

    const EMAIL = "email";

    const [name, setName] = useState(null);

    const [email, setEmail] = useState(null);

    const [errors, setErrors] = useState({});

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const confirmLoan = useRef(1);

    const { error, setErrorMessage, clearError } = useError();

    const [mlo, setMlo] = useState(null);

    const validationValues = [
        { validation: ["required", "onlyLetters"], value: name, key: NAME },
        { validation: ["required", "isEmail", "isSameEmail"], value: [email, state[GET_MLO_PROFILE]?.email], key: EMAIL },
    ];

    const showError = (errorValue, field) => {
        if (field === NAME && Array.isArray(errorValue) && errorValue.length > 0) {
            return errorValue[0] === FIRST_NAME_ERROR ? (errorValue[0]) : t(NAME_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === EMAIL && Array.isArray(errorValue) && errorValue.length > 0) {
            return errorValue[0] === EMAIL_ERROR ? t(errorValue[0]) : t(EMAIL_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    const handleDialogBeforeHide = () => {
        setEmail(null);
        setName(null);
        setErrors({});
        clearError();
        setSaving(false);
        setMlo(null);
        confirmLoan.current = 1;
        activeErrors.current = false;
        props.onClose();
    }

    const handleAddLoan = async () => {
        if (!activeErrors.current) {
            try {
                setSaving(true);
                if (email === "mail@padzilly.com") {
                    setErrorMessage(CANT_INVITE_PADZILLY_MLO, 'warning');
                    setSaving(false);
                    return;
                }
                const loan = await getMloByEmail(email, state[GET_PROFILE_TOKEN]);
                if (loan) {
                    setMlo(loan);
                    confirmLoan.current = 2
                } else {
                    const result = await inviteMLO(email, name, null, state[GET_PROFILE_TOKEN]);
                    if (!result) {
                        setErrorMessage(t(MLO_ALREADY_INVITED_ERROR, { 0: name }), 'warning');
                        setTimeout(() => { handleDialogBeforeHide(); }, 5000);
                    } else {
                        setErrorMessage(NO_MLO_ERROR, 'warning');
                        setTimeout(() => { handleDialogBeforeHide(); }, 5000);
                    }
                }
            } catch (e) {
                setErrorMessage(handleError(e));
            } finally {
                setErrors({});
                setSaving(false);
            }
        }
    }

    const handleConfirmLoan = async () => {
        try {
            setSaving(true);

            let invPro = state[GET_INVESTMENT_PROFILE];
            invPro.loanOriginatorId = mlo?.id;
            if (invPro.loan !== null) {
                invPro.loan = null;
                invPro.paymentLimit = null;
                invPro.investmentAmount = null;
            }

            const invProfile = await updateInvestmentProfile(invPro, state[GET_PROFILE_TOKEN]);
            if (invProfile) {
                dispatch({ action: GET_INVESTMENT_PROFILE, value: invProfile });
                dispatch({ action: GET_PAYMENT_LIMIT, value: invProfile.paymentLimit });
                dispatch({ action: GET_FUNDS_LIMIT, value: invProfile.investmentAmount });
                dispatch({ action: GET_MLO_ID, value: mlo?.id });
                dispatch({ action: GET_MLO_PROFILE, value: mlo });
                dispatch({ action: GET_INVESTMENT_PROFILE_LOAN, value: null });
                handleDialogBeforeHide();
            } else {
                setErrorMessage(handleError());
            }
        } catch (e) {
            setErrorMessage(handleError(e));
        } finally {
            setSaving(false);
        }
    }

    const handleCancel = () => {
        handleDialogBeforeHide();
    }

    const titleOptions = {
        1: LOAN_OFFICER_TWO_LABEL,
        2: CONFIRM_LOAN_ORIGINATOR_LABEL,
    };

    const options = {
        1: (<BaseBtn
            loading={saving}
            onClick={() => {
                const eList = validations(validationValues);
                setErrors(eList);
                (Object.keys(eList).length === 0)
                    ? activeErrors.current = false
                    : activeErrors.current = true
                handleAddLoan();
            }}
        >
            {state[GET_MLO_PROFILE] || state[GET_INVESTMENT_PROFILE_LOAN]
                ?
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ManageAccountsIcon fontSize="small" />
                    &nbsp;
                    {t(CHANGE_LOAN_ORIGINATOR_LABEL)}
                </Box>
                : <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PersonAddIcon fontSize="small" />
                    &nbsp;
                    {t(ADD_LOAN_ORIGINATOR_LABEL)}
                </Box>}
        </BaseBtn>),
        2: (<Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <BaseBtn
                label={t(CANCEL_LABEL)}
                onClick={() => { handleCancel(); }}
                variant="outlined"
            />
            <BaseBtn
                label={t(CONFIRM_LABEL)}
                onClick={() => { handleConfirmLoan(); }}
                style={{ marginLeft: "10px" }}
                loading={saving}
                disabled={!mlo?.active}
            />
        </Box>)
    }

    const content = {
        1: (<Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <p style={{ fontWeight: "bold", marginTop: "0px", textAlign: "center" }}>
                {state[GET_MLO_PROFILE] || state[GET_INVESTMENT_PROFILE_LOAN]
                    ? t(CHANGE_LOAN_ORIGINATOR_LABEL)
                    : t(DO_NOT_HAVE_LOAN_ORIGINATOR_TEXT)}
            </p>
            <BaseInput
                style={{ width: "100%" }}
                label={t(LOAN_OFFICER_NAME_LABEL)}
                startIcon="person"
                required
                value={name}
                error={showError(errors.name, NAME)}
                onInputChange={(value) => {
                    validationValues[0].value = value;
                    setName(value);
                    setErrors(validations(validationValues));
                }}
            />
            <BaseInput
                style={{ width: "100%" }}
                label={t(LOAN_OFFICER_EMAIL_LABEL)}
                startIcon="email"
                required
                value={email}
                error={showError(errors.email, EMAIL)}
                onInputChange={(value) => {
                    validationValues[1].value = [value, state[GET_MLO_PROFILE]?.email];
                    setEmail(value);
                    setErrors(validations(validationValues));
                }}
            />
        </Box>),
        2: (<Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>


            {!mlo?.active
                ? <Alert severity="warning">
                    <p style={{ margin: 0 }}>
                        {t(MLO_NOT_ACTIVE_TEXT)}.&nbsp;
                        <span
                            style={{ color: SECONDARY_COLOR, cursor: "pointer", textDecoration: "underline" }}
                            onClick={() => window.open(state[GET_LANGUAGE] === ES_US ? CONTACT_ES : CONTACT_EN, "_blank")}
                        >
                            {t(CONTACT_LABEL)}
                        </span>
                    </p>

                </Alert>
                : <>
                    <p style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "1.2rem",
                        marginTop: "0px"
                    }}>
                        {t(LOAN_OFFICER_CONNECTED_TEXT)}.
                    </p>
                    <p
                        style={{ textAlign: "center" }}
                    >
                        {t(WE_ARE_CONTACTING_TEXT)}.
                    </p>
                </>
            }

            <Box
                sx={{
                    width: "100%"
                }}
            >
                <p
                    style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize: "1.2rem",
                    }}
                >
                    {t(LOAN_OFFICER_LABEL)}
                </p>
                <p>{mlo?.name}</p>
                <p>{mlo?.companyName}</p>
                <p
                    style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                    }}
                ><strong>{t(EMAIL_LABEL)}: </strong><a style={{ color: "black" }} href={`mailto:${mlo?.email}`}>{mlo?.email}</a></p>
                <p
                    style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                    }}
                ><strong>{t(PHONE_LABEL)}: </strong><span style={{ color: "black" }}>{formatPhoneNumber(mlo?.phone)}</span></p>
            </Box>
        </Box>)
    }

    return (
        <BaseDialog
            {...props}
            maxWidth={"xs"}
        >
            <BaseDialogHeader
                title={t(titleOptions[confirmLoan.current]) || ""}
                close={() => { handleDialogBeforeHide(); }}
            />
            <BaseDialogBody>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingLeft: "14px",
                    paddingRight: "14px"
                }}>
                    <BaseAlert alert={error} onClose={() => { clearError(); }} time={5000} />
                    {content[confirmLoan.current] || null}
                </Box>
            </BaseDialogBody>
            <BaseDialogFooter className="setProperties">
                {options[confirmLoan.current] || null}
            </BaseDialogFooter>
        </BaseDialog>
    );
}