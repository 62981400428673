import { Box, Link } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { SELLER_REBATE_ONLY_LAP_TEXT } from "../../../i18n/i18nText";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from "react";
import { LEARN_MORE_LABEL } from "../../../i18n/i18nLabel";
import { useStore } from "../../../hooks/store/store";
import { GET_LANGUAGE } from "../../../constants/store/getters";
import { ES_US } from "../../../constants/constants";
import { HOW_ADJUST_SEARCH_PRO, HOW_ADJUST_SEARCH_PRO_ES } from "../../../constants/urls";

export default function SellerInfo() {

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const { t } = useTranslation();

    const { state } = useStore();

    return (
        <Box sx={{ cursor: "pointer", display: "flex" }}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    title={
                        <Box>
                            <span>{t(SELLER_REBATE_ONLY_LAP_TEXT)}. </span>
                            <Link onClick={() => window.open(state[GET_LANGUAGE] === ES_US ? HOW_ADJUST_SEARCH_PRO_ES : HOW_ADJUST_SEARCH_PRO, "_blank")}>
                                {t(LEARN_MORE_LABEL)}
                            </Link>
                        </Box>
                    }
                    placement="top"
                    arrow

                >
                    <HelpIcon fontSize="small" onClick={handleTooltipOpen} />
                </Tooltip>
            </ClickAwayListener>
        </Box>
    );
}