import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import { signOut } from '../../services/authServices';
import { useStore } from '../../hooks/store/store';
import {
    GET_LANDING_PAGE_ELEMENTS,
    GET_LANGUAGE,
    GET_LAST_SEARCH,
    GET_LOCATION,
    GET_NEAR_PROPERTIES,
    GET_PROFILE,
    GET_PROFILE_TOKEN,
    GET_STORE_COMPLETE
} from '../../constants/store/getters';
import { handleError } from '../../utils/functions';
import BaseAlert from '../BaseComponents/BaseAlert';
import { useError } from '../../hooks/handleError';
import { deepOrange } from '@mui/material/colors';
import {
    FAVORITES_ROUTE,
    LANDING_PAGE,
    PROFILE_ROUTE
} from '../../constants/routes';
import { useHistory } from "react-router-dom";
import { GETTERS } from '../../hooks/store/getters';
import { useTranslation } from 'react-i18next';
import {
    FAVORITES_LABEL,
    PROFILE_LABEL,
    SIGN_OUT_LABEL
} from '../../i18n/i18nLabel';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { GET_NEW_VALUES_FOR_SEARCH, PRIMARY_COLOR } from '../../constants/constants';

export default function SessionActions() {

    const { t } = useTranslation();

    const history = useHistory();

    const { state, dispatch } = useStore();

    const { error, setErrorMessage, clearError } = useError();

    const [open, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const popperRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popperRef.current && !popperRef.current.contains(event.target) && !anchorEl.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const destroySession = async () => {
        try {
            await signOut(state[GET_PROFILE_TOKEN]);

            localStorage.clear();

            for (const key in GETTERS) {
                if (key !== GET_NEAR_PROPERTIES
                    && key != GET_LOCATION
                    && key !== GET_STORE_COMPLETE
                    && key !== GET_LANDING_PAGE_ELEMENTS
                    && key !== GET_LANGUAGE
                    && key !== GET_LAST_SEARCH) {
                    dispatch({ action: key, value: null });
                }
                if (key === GET_LAST_SEARCH) {
                    localStorage.setItem("last_search", JSON.stringify(GET_NEW_VALUES_FOR_SEARCH()));
                    dispatch({ action: key, value: GET_NEW_VALUES_FOR_SEARCH() });
                }
            }
            history.push(LANDING_PAGE);
        } catch (e) {
            setErrorMessage(handleError(e));
        }
    }

    const getAvatar = () => {
        if (state[GET_PROFILE]) {
            const name = state[GET_PROFILE].firstName[0];
            const last = state[GET_PROFILE].lastName[0];
            return name + last;
        } else {
            return "GU";
        }
    }

    return (
        <>
            <BaseAlert alert={error} onClose={() => { clearError(); }} />
            <Popper
                sx={{ zIndex: 99 }}
                open={open}
                anchorEl={anchorEl}
                placement={"bottom"}
                transition
                ref={popperRef}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "8px"
                        }}>
                            <Button
                                style={{
                                    fontSize: ".7rem",
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    color: PRIMARY_COLOR,
                                }}
                                onClick={() => {
                                    destroySession();
                                }}>
                                <Logout fontSize="small" />
                                &nbsp;
                                <span>{t(SIGN_OUT_LABEL)}</span>
                            </Button>
                            <Button
                                style={{
                                    fontSize: ".7rem",
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    color: PRIMARY_COLOR,
                                }}
                                onClick={() => {
                                    history.push(FAVORITES_ROUTE);
                                }}>
                                <FavoriteIcon fontSize="small" style={{ color: "red" }} />
                                &nbsp;
                                <span>{t(FAVORITES_LABEL)}</span>
                            </Button>
                            <Button
                                style={{
                                    fontSize: ".7rem",
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    color: PRIMARY_COLOR,
                                }}
                                onClick={() => {
                                    history.push(PROFILE_ROUTE);
                                }}>
                                <AccountCircleOutlinedIcon fontSize="small" />
                                &nbsp;
                                <span>{t(PROFILE_LABEL)}</span>
                            </Button>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <IconButton
                    onClick={(event) => {
                        setOpen(true);
                        setAnchorEl(event.currentTarget);
                    }}
                    size="small"
                    sx={{ mr: 1 }}
                >
                    <Avatar
                        src={""}
                        sx={{
                            width: 30,
                            height: 30,
                            textTransform: "uppercase",
                            bgcolor: deepOrange[400],
                            fontSize: "0.9rem"
                        }}>
                        {getAvatar()}
                    </Avatar>
                </IconButton>
            </Box>
        </>
    );
}