import axios from "axios";
import {
    GET_LOAN_BY_ID,
    GET_LOAN_BY_USER_EMAIL,
    GET_MLO_BY_EMAIL,
    GET_MLO_BY_ID,
    INVITE_MLO
} from "../endpoints/endpoints";

export async function getMloByEmail(email, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {}
        const mlo = await axios.get(GET_MLO_BY_EMAIL + "?email=" + encodeURIComponent(email), {
            headers
        });
        return mlo.data;
    } catch (error) {
        throw error;
    }
}

export async function inviteMLO(email, name, phone, profile_token) {
    try {
        const data = {
            id: null,
            companyName: null,
            active: false,
            name: name,
            email: email,
            phone: phone,
        };
        const headers =
            profile_token
                ? { Authorization: `Bearer ${profile_token}` }
                : {};
        const result = await axios.post(INVITE_MLO, data, {
            headers,
        });
        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function getMloById(id, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {}
        const mlo = await axios.get(GET_MLO_BY_ID + "?id=" + id, {
            headers
        });
        return mlo.data;
    } catch (error) {
        throw error;
    }
}

export async function getLoanByUserEmail(profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {}
        const loan = await axios.get(GET_LOAN_BY_USER_EMAIL, {
            headers
        });
        return loan.data;
    } catch (error) {
        throw error;
    }
}

export async function getLoanById(id, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {}
        const loan = await axios.get(GET_LOAN_BY_ID + "?id=" + id, {
            headers
        });
        return loan.data;
    } catch (error) {
        throw error;
    }
}