import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function BaseDialog({ onClose, open, maxWidth, className, children, fullScreen }) {

    const theme = useTheme();

    const isFS = useMediaQuery(theme.breakpoints.down('sm')) || fullScreen;

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            return false;
        }
        if (reason === "escapeKeyDown") {
            return false;
        }
        if (typeof onClose === "function") {
            onClose();
        }
        return event;
    }

    return (
        <Dialog
            fullScreen={isFS}
            open={open}
            onClose={handleClose}
            maxWidth={maxWidth ?? "xl"}
            className={className}
        >
            {children}
        </Dialog>
    );
}