import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import { EN_US, ES_US, PRIMARY_COLOR } from '../../constants/constants';
import { Button, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import English from '../Images/English';
import Spanish from '../Images/Spanish';

export default function BaseChangeLanguage() {

    const { i18n } = useTranslation();

    const [lang, setLang] = useState(i18n.language);

    const handleLanguage = (language) => () => {
        if (language === EN_US) {
            localStorage.setItem("i18nextLng", EN_US);
            window.location.reload();
        } else {
            localStorage.setItem("i18nextLng", ES_US);
            window.location.reload();
        }
        setOpen(false);
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const popperRef = useRef(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (popperRef.current && !popperRef.current.contains(event.target) && !anchorEl.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    return (
        <Box>
            <Popper
                sx={{ zIndex: 100 }}
                open={open}
                anchorEl={anchorEl}
                placement={"bottom"}
                transition
                ref={popperRef}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "5px"
                        }}>
                            <Button
                                style={{ fontSize: ".7rem", color: lang === EN_US ? "grey" : PRIMARY_COLOR }}
                                onClick={handleLanguage(EN_US)}
                                disabled={lang === EN_US}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Box sx={{ width: "23px", marginRight: "2px", display: "flex" }}>
                                        <English />
                                    </Box>
                                    <span>English</span>
                                </Box>
                            </Button>
                            <Button
                                style={{ fontSize: ".7rem", color: lang === ES_US ? "grey" : PRIMARY_COLOR }}
                                onClick={handleLanguage(ES_US)}
                                disabled={lang === ES_US}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Box sx={{ width: "23px", marginRight: "2px", display: "flex" }}>
                                        <Spanish />
                                    </Box>
                                    <span>Español</span>
                                </Box>
                            </Button>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <IconButton
                onClick={handleClick}
                sx={{
                    ml: 1,
                    mr: 1,
                    width: "40px",
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }}
            >
                {lang === ES_US
                    ? <Spanish />
                    : <English />
                }
            </IconButton>
        </Box>
    );
}