import { getUserAccount } from "../../services/authServices"
import {
    getInvestmentProfile as getInvProfile
} from "../../services/investmentProfileServices";
import { getLandingPageElements, getLocationByIP, getNearProperties } from "../../services/landingServices";
import {
    getLoanByUserEmail, getMloById
} from "../../services/loanServices";
import {
    getFavorites as getFavoritesProperties
} from "../../services/propertiesServices";
import { getSavedSearches as getSearches } from "../../services/savedSearchesServices";

export async function getProfile(id) {
    try {
        return await getUserAccount(id);
    } catch (error) {
        throw error;
    }
};

export async function getInvestmentProfile(id) {
    try {
        return await getInvProfile(id);
    } catch (error) {
        throw error;
    }
}

export async function getMloProfile(id, token) {
    try {
        return await getMloById(id, token);
    } catch (error) {
        throw error;
    }
}

export async function getLoanByEmail(token) {
    try {
        return await getLoanByUserEmail(token);
    } catch (error) {
        throw error;
    }
}

export async function getFavorites(page, pageSize, userId, token) {
    try {
        return await getFavoritesProperties(page, pageSize, userId, token);
    } catch (error) {
        throw error;
    }
}

export async function setLastResults(json, favorites) {
    try {
        const lastResults = json.slice(0, 30);
        favorites.forEach(item => {
            const index = lastResults.findIndex(prop => prop.propertyId === item.propertyId);
            if (index != -1) {
                lastResults[index].isFavoriteProperty = true;
            }
        });
        localStorage.setItem("last_search_results", JSON.stringify(lastResults));
        return lastResults;
    } catch (error) {
        throw error;
    }
}

export async function getLocation() {
    try {
        return await getLocationByIP();
    } catch (error) {
        throw error;
    }
}

export async function getNearPropertiesLandingPage(city, state) {
    try {
        return await getNearProperties(city, state);
    } catch (error) {
        throw error;
    }
}

export async function getLandingElements() {
    try {
        return await getLandingPageElements();
    } catch (error) {
        throw error;
    }
}

export async function getSavedSearches(email, token) {
    try {
        return await getSearches(email, token);
    } catch (error) {
        throw error;
    }
}