import { Box } from "@mui/material";
import Joyride from 'react-joyride';
import { useTranslation } from 'react-i18next';
import {
    BACK_LABEL,
    CLOSE_LABEL,
    HOME_SEARCH_GOALS_LABEL,
    LAST_LABEL,
    NEXT_STEP_LABEL,
    SKIP_LABEL
} from "../../i18n/i18nLabel";
import {
    TRIP_HOME_SEARCH_GOALS_CASH_TEXT,
    TRIP_HOME_SEARCH_GOALS_PAYMENT_TEXT
} from "../../i18n/i18nText";
import { useEffect, useState } from "react";
import { PRIMARY_COLOR } from "../../constants/constants";

export default function HomeSearchGoalsTrip({
    onClose,
    run,
    continuous,
    scrollToFirstStep,
    showSkipButton,
}) {

    const { t } = useTranslation();

    const [stepIndex, setStepIndex] = useState(null);

    const steps = [
        {
            title: t(HOME_SEARCH_GOALS_LABEL),
            target: '#monthlyPayment',
            content: t(TRIP_HOME_SEARCH_GOALS_PAYMENT_TEXT),
            disableBeacon: true,
            placement: 'auto',
        },
        {
            title: t(HOME_SEARCH_GOALS_LABEL),
            target: '#downPaymentNeeded',
            content: t(TRIP_HOME_SEARCH_GOALS_CASH_TEXT),
            disableBeacon: true,
            placement: 'auto',
        },
    ];

    useEffect(() => {
        const step0 = document.querySelector('#react-joyride-step-0');
        const step1 = document.querySelector('#react-joyride-step-1');

        if (step0) {
            step0.style.zIndex = '9999';
            step0.style.position = 'relative';
        }
        if (step1) {
            step1.style.zIndex = '9999';
            step1.style.position = 'relative';
        }
    }, [
        stepIndex
    ]);

    return (
        <Box>
            <Joyride
                steps={steps}
                run={run}
                continuous={continuous}
                scrollToFirstStep={scrollToFirstStep}
                showSkipButton={showSkipButton}
                callback={(data) => {
                    setStepIndex(data.index);
                    if (data.status === 'finished' || data.status === 'skipped' || data.action === 'close') {
                        onClose();
                    }
                }}
                styles={{
                    options: {
                        primaryColor: PRIMARY_COLOR,
                    },
                    tooltip: {
                        fontSize: '.69rem',
                        padding: '10px',
                        zIndex: 100,
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 100
                    },
                    tooltipTitle: {
                        fontSize: ".8rem",
                        marginRight: "30px"
                    },
                    buttonSkip: {
                        fontSize: ".7rem"
                    },
                    buttonBack: {
                        fontSize: ".7rem"
                    },
                    buttonNext: {
                        fontSize: ".7rem"
                    },
                    buttonClose: {
                        fontSize: ".7rem",
                    },
                    tooltipContent: {
                        padding: "0px",
                        marginTop: "8px"
                    }
                }}
                locale={{
                    back: t(BACK_LABEL),
                    close: t(CLOSE_LABEL),
                    last: t(LAST_LABEL),
                    next: t(NEXT_STEP_LABEL),
                    skip: t(SKIP_LABEL),
                }}
            />
        </Box>
    );
}