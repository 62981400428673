import axios from 'axios';
import {
    GET_SAVED_SEARCHES,
    CREATE_SAVED_SEARCH,
    SET_ALERTS,
    REMOVE_SAVED_SEARCH
} from '../endpoints/endpoints';

function isValidJson(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

function getFormattedDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const milliseconds = now.getMilliseconds().toString().padStart(3, '0') + '0000';

    const offset = -now.getTimezoneOffset();
    const offsetSign = offset >= 0 ? '+' : '-';
    const offsetHours = Math.floor(Math.abs(offset) / 60).toString().padStart(2, '0');
    const offsetMinutes = (Math.abs(offset) % 60).toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
}

export async function getSavedSearches(email, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {};

        const result = await axios.post(GET_SAVED_SEARCHES, { email: email }, { headers });
        result.data.forEach(s => {
            if (isValidJson(s.search) && isValidJson(s.input)) {
                s.search = JSON.parse(s.search);
                s.input = JSON.parse(s.input);
            }
        });

        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function createSavedSearch(search, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {};

        if (typeof search.search.downpayment === 'string') {
            search.search.downpayment = parseFloat(search.search.downpayment);
        }
        const result = await axios.post(CREATE_SAVED_SEARCH, {
            email: search.email,
            search: JSON.stringify(search.search),
            input: JSON.stringify(search.input),
            created: getFormattedDate()
        }, {
            headers
        });

        result.data.search = search.search;
        result.data.input = search.input;

        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function setAlerts(id, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {};

        const result = await axios.post(SET_ALERTS, { id: id }, {
            headers
        });
        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function deleteSavedSearch(id, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {};

        const result = await axios.post(REMOVE_SAVED_SEARCH, { id: id }, {
            headers
        });
        return result.data;
    } catch (error) {
        throw error;
    }
}