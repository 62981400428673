import Box from '@mui/material/Box';

export default function BaseVideo({ src }) {

    return (
        <Box sx={{ width: "100%" }}>
            <Box style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                    src={src}
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style={{
                        border: 0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}
                />
            </Box>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </Box>
    );
}