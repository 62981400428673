import * as LABELS from "../i18nLabel";

export default {
  [LABELS.HOMES_LABEL]: "Casas",
  [LABELS.FAVORITES_LABEL]: "Favoritas",
  [LABELS.PROFILE_LABEL]: "Perfil",
  [LABELS.SETTINGS_LABEL]: "Configuración",
  [LABELS.SIGN_OUT_LABEL]: "Cerrar Sesión",
  //HOMES
  [LABELS.CURRENT_LOCATION_LABEL]: "Ubicación Actual",
  [LABELS.PRICE_LABEL]: "Precio",
  [LABELS.BEDROOMS_LABEL]: "Dormitorios",
  [LABELS.SIZE_LABEL]: "Tamaño",
  [LABELS.FULL_BATH_LABEL]: "Baños completos",
  [LABELS.HOME_SIZE_LABEL]: "Tamaño de casa",
  [LABELS.LOT_SIZE_LABEL]: "Tamaño de propiedad",
  [LABELS.YEAR_BUILT_LABEL]: "Año de construcción",
  [LABELS.ORDER_BY_LABEL]: "Ordenar por",
  [LABELS.PAYMENT_LABEL]: "Filtrar por Pago",
  [LABELS.CASH_LABEL]: "Filtrar por Inversión",
  [LABELS.FILTERS_LABEL]: "Más Filtros",
  [LABELS.BATHROOMS_LABEL]: "Baños",
  [LABELS.HOME_TYPE_LABEL]: "Tipo de casa",
  [LABELS.CONDOMINIUM_LABEL]: "Condominio",
  [LABELS.SINGLE_FAMILY_LABEL]: "Unifamiliar",
  [LABELS.MOBILE_HOME_LABEL]: "Casa móvil",
  [LABELS.TOWNHOUSE_LABEL]: "Townhouse",
  [LABELS.SEARCH_HOMES_LABEL]: "Buscar casas",
  [LABELS.RESET_LABEL]: "Reestablecer",
  [LABELS.SEARCH_LABEL]: "Buscar",
  [LABELS.MAX_LABEL]: "Máx",
  [LABELS.MIN_LABEL]: "Mín",
  [LABELS.LIVE_SEARCH_LABEL]: "Ciudad, Código Postal o Dirección",
  [LABELS.NO_LOCATIONS_LABEL]: "Sin ubicaciones",
  [LABELS.ANY_LABEL]: "Cualquier",
  [LABELS.NEXT_LABEL]: "Más Propiedades",
  [LABELS.SEARCH_RESULT_LABEL]: "Casas a la venta en",
  [LABELS.QUALIFIED_LABEL]: "Califica para",
  [LABELS.TO_LABEL]: "a",
  [LABELS.FROM_LABEL]: "Desde",
  [LABELS.DELETE_SEARCH_LABEL]: "Eliminar Búsqueda Guardada",
  [LABELS.COUNTY_LABEL]: "Condado",
  //PROPERTY CARD
  [LABELS.NEW_CONSTRUCTION_LABEL]: "Construcción nueva",
  [LABELS.PENDING_LABEL]: "Pendiente",
  [LABELS.UNDER_CONTRACT_LABEL]: "Bajo Contrato",
  [LABELS.COMING_SOON_LABEL]: "Próximamente",
  [LABELS.FOR_SALE_LABEL]: "En venta",
  [LABELS.NO_INFO_LABEL]: "Sin Información",
  [LABELS.UNKNOWN_ADDRESS_LABEL]: "Dirección Desconocida",
  [LABELS.UNKNOWN_CITY_LABEL]: "Ciudad Desconocida",
  [LABELS.UNKNOWN_STATE_LABEL]: "Estado Desconocido",
  [LABELS.UNKNOWN_ZIP_CODE_LABEL]: "Código Zip Desconocido",
  [LABELS.NOTE_LABEL]: "NOTA",
  [LABELS.HOA_FEE_LABEL]: "Tarifa HOA",
  [LABELS.TAXES_LABEL]: "Impuestos",
  [LABELS.MONTHLY_PAYMENT_LABEL]: "Pago Mensual Estimado",
  [LABELS.CASH_NEEDED_LABEL]: "Inversión Necesaria",
  [LABELS.CASH_NEEDED_HIGHER_LABEL]:
    "es más alto debido al programa de límites de préstamos por condado",
  [LABELS.DOWNPAYMENT_HIGHER_LABEL]:
    "El depósito es más alto debido al programa de límites de préstamos por condado. El límite del condado es: ",

  //PAYMENT BREAKDOWN
  [LABELS.LEARN_MORE_LABEL]: "Aprenda Más",
  [LABELS.PAYMENT_NOT_INCLUDE_LABEL]: "El pago no incluye",
  [LABELS.ASSOCIATION_FEE_LABEL]: "de cuota de asociación",
  [LABELS.INSURANCE_LABEL]: "Seguro",
  [LABELS.PROPERTY_TAX_LABEL]: "Impuestos sobre la propiedad",
  [LABELS.MORTGAGE_LABEL]: "Seguro Hipotecario",
  //FAV CARD
  [LABELS.VIEW_MORE_FAV_CARD_LABEL]: "Ver Más",
  //FAVORITES
  [LABELS.COMPARE_LABEL]: "Comparar",
  [LABELS.CANCEL_LABEL]: "Cancelar",
  [LABELS.SAVED_HOMES_LABEL]: "Propiedades Favoritas",
  [LABELS.SELECTED_LABEL]: "Seleccionadas",
  [LABELS.SELECTED_TO_COMPARE_LABEL]: "Selecciona 2-5 casas para comparar",
  [LABELS.ALERTS_ON_LABEL]: "Alertas",
  [LABELS.CREATED_LABEL]: "Creado",
  [LABELS.SAVE_SEARCH_LABEL]: "Guardar Búsqueda",
  [LABELS.SAVED_LABEL]: "Búsqueda Guardada",
  [LABELS.SAVED_SEARCHES_LABEL]: "Búsquedas Guardadas",
  //PROP COMPARISON
  [LABELS.STREET_ADDRESS_LABEL]: "Dirección",
  [LABELS.STATUS_LABEL]: "Estado",
  [LABELS.CITY_LABEL]: "Ciudad",
  [LABELS.STATE_LABEL]: "Estado",
  [LABELS.ZIPCODE_LABEL]: "Código Postal",
  [LABELS.LISTING_PRICE_LABEL]: "Precio Listado",
  [LABELS.SAVED_OFFER_PRICE_LABEL]: "Precio de Oferta Guardado",
  [LABELS.SELLER_REBATE_LABEL]: "Reemblso/Contribución del Vendedor",
  [LABELS.DOWN_PAYMENT_LABEL]: "Depósito",
  [LABELS.ANNUAL_TAX_AMOUNT_LABEL]: "Monto del Impuesto Anual",
  [LABELS.INVESTMENT_LABEL]: "Inversión",
  [LABELS.HALF_BATHROOMS_LABEL]: "Medio Baño",
  [LABELS.SQUARE_FEET_LABEL]: "Pies Cuadrados",
  [LABELS.MONTHLY_LABEL]: "Mensual",
  [LABELS.CLOSE_LABEL]: "Cerrar",
  [LABELS.HOME_COMPARISON_LABEL]: "Comparación de Casas",
  [LABELS.PROP_PAYMENT_LABEL]: "Pago",
  //IMPORT PROP
  [LABELS.IMPORT_PROPERTY_LABEL]: "Importar Propiedad",
  [LABELS.SEARCH_AND_IMPORT_PROP_LABEL]: "Buscar e importar propiedad",
  //SETTINGS
  [LABELS.TERMS_OF_USE_LABEL]: "Términos de uso",
  [LABELS.PRIVACY_POLICY_LABEL]: "Privacidad y Seguridad",
  [LABELS.LOG_IN_LABEL]: "Iniciar Sesión",
  //Profile
  [LABELS.PERSONAL_INFO_LABEL]: "Información Personal",
  [LABELS.NAME_LABEL]: "Nombre",
  [LABELS.EMAIL_LABEL]: "Correo Electrónico",
  [LABELS.SIGN_UP_LABEL]: "Crear Cuenta",
  [LABELS.COMPANY_LABEL]: "Compañía",
  [LABELS.PHONE_LABEL]: "Teléfono",
  [LABELS.LOAN_ORIGINATOR_PERSONAL_INFO_LABEL]:
    "INFORMACIÓN PERSONAL DE SU PRESTAMISTA",
  [LABELS.NO_LOAN_ORIGINATOR_LABEL]: "INVITE A SU PRESTAMISTA u ORIGINADOR DE PRÉSTAMO",
  [LABELS.ADD_LOAN_ORIGINATOR_LABEL]: "Invitar a oficial de préstamo",
  [LABELS.CHANGE_MLO_LABEL]: "Cambiar MLO",
  [LABELS.REMOVE_MLO_LABEL]: "Eliminar MLO",
  [LABELS.INVESTMENT_PROFILE_LABEL]: "PERFIL DE INVERSIÓN",
  [LABELS.PAYMENT_LIMIT_LABEL]: "Límite de Pago",
  [LABELS.FUND_LIMIT_LABEL]: "Límite de fondos",
  [LABELS.SAVE_CHANGES_LABEL]: "Guardar Cambios",
  [LABELS.GO_BACK_TO_SEARCH_LABEL]: "Regresar a Búsqueda",
  [LABELS.MISSING_FIRST_NAME_LABEL]: "Nombre Desconocido",
  [LABELS.MISSING_LAST_NAME_LABEL]: "Apellido Desconocido",
  [LABELS.MISSING_EMAIL_LABEL]: "Correo Electrónico Desconocido",
  [LABELS.LOAN_ORIGINATOR_LABEL]: "Originador de Préstamo",
  [LABELS.CHANGE_LOAN_ORIGINATOR_LABEL]: "Cambiar Originador de Préstamo",
  [LABELS.CONFIRM_LOAN_ORIGINATOR_LABEL]: "Confirmar Originador de Préstamo",
  [LABELS.LOAN_OFFICER_NAME_LABEL]: "Nombre del Oficial de Préstamos",
  [LABELS.LOAN_OFFICER_EMAIL_LABEL]:
    "Correo Electrónico del Oficial de Préstamos",
  [LABELS.LOAN_OFFICER_LABEL]: "Su Oficial de Préstamos",
  [LABELS.CONFIRM_LABEL]: "Confirmar",
  [LABELS.CONFIRMATION_LABEL]: "Confirmación",
  [LABELS.CREATE_INVESTMENT_PROFILE_LABEL]: "Crear perfil de inversión",
  [LABELS.LOAN_INFORMATION_LABEL]: "Información del Préstamo",
  [LABELS.LOAN_TYPE_LABEL]: "Tipo de Préstamo",
  [LABELS.TERM_LABEL]: "Término",
  [LABELS.YEARS_LABEL]: "años",
  [LABELS.LOAN_OFFICER_TWO_LABEL]: "Oficial de Préstamo",
  [LABELS.SUBSCRIBE_LABEL]: "Suscribirse",
  [LABELS.BUY_SUBSCRIPTION_LABEL]: "Comprar Suscripción",
  [LABELS.PAY_LABEL]: "Pagar",
  [LABELS.UPLOAD_DOCUMENTS_LABEL]: "Añadir préstamo",
  //LOADING
  [LABELS.LOADING_LABEL]: "Gracias por su espera",
  //PROP DETAIL
  [LABELS.SHARE_LABEL]: "Compartir",
  [LABELS.SAVE_LABEL]: "Guardar como Favorita",
  [LABELS.QUALIFICATION_LABEL]: "Carta de calificación",
  [LABELS.OFFER_PRO_LABEL]: "OfferPro",
  [LABELS.REMOVE_LABEL]: "Eliminar de Favoritas",
  [LABELS.VIEW_IMAGE_LABEL]: "Ver Imagen",
  [LABELS.VIEW_ALL_IMAGES_LABEL]: "Ver todas,",
  [LABELS.PHOTOS_LABEL]: "Imágenes",
  [LABELS.NO_PHOTOS_LABEL]: "Sin Fotos Disponibles",
  [LABELS.BUILT_IN_LABEL]: "Construido en",
  [LABELS.MORTGAGE_INS_LABEL]: "Seguro Hipotecario",
  [LABELS.ABOUT_THIS_HOME_LABEL]: "Acerca de esta casa",
  [LABELS.LISTED_BY_LABEL]: "Listada por",
  //LOGIN
  [LABELS.WELCOME_BACK_LABEL]: "Bienvenido nuevamente",
  [LABELS.PASSWORD_LABEL]: "Contraseña",
  [LABELS.FORGOT_YOUR_PASSWORD_LABEL]: "¿Olvidó su contraseña?",
  [LABELS.DO_NOT_HAVE_ACCOUNT_LABEL]: "¿No tiene una cuenta?",
  [LABELS.GO_BACK_LABEL]: "Ir Atrás",
  //FORGOT PASS
  [LABELS.FORGOT_PASSWORD_LABEL]: "Contraseña olvidada",
  [LABELS.SEND_EMAIL_LABEL]: "Enviar correo",
  [LABELS.BACK_TO_LABEL]: "Volver a",
  //CREATE ACCOUNT
  [LABELS.CREATE_ACCOUNT_LABEL]: "Crear Cuenta",
  [LABELS.FIRST_NAME_LABEL]: "Nombre",
  [LABELS.LAST_NAME_LABEL]: "Apellido",
  [LABELS.FULL_NAME_LABEL]: "Nombre Completo",
  [LABELS.NO_WHITE_SPACES_LABEL]: "Sin espacios en blanco",
  [LABELS.MIX_PASS_CHARACTERS_LABEL]:
    "Combinación de letras mayúsculas y minúsculas, números y símbolos",
  [LABELS.EIGHT_CHARACTERS_LABEL]: "Al menos 8 caracteres",
  [LABELS.ALREADY_HAVE_AN_ACCOUNT_LABEL]: "¿Ya tienes una cuenta?",
  //VERIFY
  [LABELS.VERIFY_LABEL]: "Verificar",
  [LABELS.RESEND_LABEL]: "Reenviar",
  [LABELS.EXPIRES_IN_LABEL]: "Expira en",
  //SELECT LOAN
  [LABELS.PRE_APPROVED_LABEL]: "He sido preaprobado",
  [LABELS.NOT_APPROVED_LABEL]: "Aún no hay aprobación de préstamo",
  //INV PROFILE
  [LABELS.CHOOSE_LABEL]: "Escoja",
  [LABELS.PADZILLY_CONNECTED_LENDER_LABEL]:
    "Conéctese para obtener la precalificación",
  [LABELS.TELL_US_HOME_SEARCH_GOALS_LABEL]:
    "Dinos tus objetivos de búsqueda de vivienda",
  [LABELS.DESIRED_MORTGAGE_LABEL]:
    "Ingrese el límite de pago de hipoteca deseado",
  [LABELS.AVAILABLE_FUNDS_LABEL]: "Ingrese su límite de fondos disponibles",
  [LABELS.CONTINUE_LABEL]: "Continuar",
  //SWIFTMATCH
  [LABELS.SWIFTMATCH_READY_LABEL]: "¡Su perfil de SearchPro está listo!",
  [LABELS.MAXIMUM_PAYMENT_LIMIT_LABEL]: "Límite de pago máximo deseado",
  [LABELS.SAVINGS_AND_FUNDS_LIMIT_LABEL]: "Límite de ahorros y fondos",
  [LABELS.PRE_APPROVED_LOAN_PROFILE_LABEL]: "Perfil de préstamo preaprobado",
  [LABELS.TEMPORARY_LOAN_PROFILE_LABEL]: "Perfil de préstamo temporal",
  [LABELS.CONVENTIONAL_LABEL]: "Préstamo Convencional",
  [LABELS.FHA_LABEL]: "Préstamo FHA",
  [LABELS.ALTERNATE_LOAN_PROFILE]: "Perfil de préstamo alternativo",
  [LABELS.INCREASE_DOWN_PAYMENT_LABEL]:
    "Puedes aumentar el pago inicial para tu búsqueda",
  [LABELS.LETS_GO_LABEL]: "¡Vamos!",
  //LOAN OFFICER
  [LABELS.START_HOME_MATCHING_LABEL]: "¡Empieza la búsqueda!",
  [LABELS.ENTER_LOAN_OFFICER_INFO_LABEL]:
    "Ingrese la información de contacto del oficial de préstamos",
  [LABELS.LOAN_OFFICER_PHONE_LABEL]: "Móvil del oficial de préstamo",
  [LABELS.CONFIRM_YOUR_LENDER_LABEL]: "Por favor confirme su prestamista",
  [LABELS.IS_MY_LENDER_LABEL]: "Sí! Es correcto",
  [LABELS.IS_NOT_MY_LENDER_LABEL]: "Este no es mi prestamista",
  [LABELS.CHANGE_PASSWORD_LABEL]: "Cambiar Contraseña",
  [LABELS.ENTER_NEW_PASSWORD_LABEL]: "Establezca su nueva contraseña",
  [LABELS.CONFIRM_PASSWORD_LABEL]: "Confirmar Contraseña",
  [LABELS.SUBMIT_LABEL]: "Enviar",
  //404
  [LABELS.PAGE_NOT_FOUND_LABEL]: "Página no encontrada",
  //NO PROP FOUND
  [LABELS.NO_PROPERTIES_FOUND_LABEL]: "¡Ups! No se encontraron propiedades todavía",
  [LABELS.TRY_THE_FOLOWING_LABEL]: "Intente lo siguiente",
  [LABELS.HERE_LABEL]: "aquí",
  //HOME SEARCH GOALS
  [LABELS.HOME_SEARCH_GOALS_LABEL]: "Presupuesto para la compra de Casas",
  [LABELS.START_SEARCHING_LABEL]: "Empezar a buscar",
  //FAV
  [LABELS.ACCOUNT_REQUIRED_LABEL]: "Cuenta Requerida",
  [LABELS.NO_FAVORITE_PROPERTIES_YET_LABEL]: "Aún no hay propiedades favoritas",
  [LABELS.SWIFTMATCH_PROFILE_LABEL]: "Perfil SearchPro",
  //OFFER PRO
  [LABELS.RESET_ALL_LABEL]: "Resetear Todo",
  [LABELS.OFFER_PRICE_LABEL]: "Precio de oferta",
  [LABELS.PRINT_OFFER_LETTERS_LABEL]: "Imprimir cartas de oferta",
  [LABELS.WITH_THE_ABOVE_TERMS_LABEL]: "Con los términos anteriores",
  [LABELS.PADZILLY_OFFER_PRO_LABEL]: "Padzilly Oferta Pro",
  [LABELS.BACK_TO_PROP_DETAILS_LABEL]: "Volver a los detalles de la propiedad",
  [LABELS.ANNUAL_LABEL]: "Anual",
  //SNACK BAR FAV
  [LABELS.VIEW_LABEL]: "Ver",
  [LABELS.ADDED_TO_FAV_LABEL]: "Agregado a Propiedades Favoritas",
  //ERROR LABELS
  [LABELS.FUNDS_ERROR_LABEL]: "El fondo",
  [LABELS.MORTGAGE_ERROR_LABEL]: "La hipoteca",
  [LABELS.IS_REQUIRED_ERROR_LABEL]: "es requerido(a)",
  [LABELS.URL_LABEL]: "El link",
  [LABELS.NAME_ERROR_LABEL]: "El nombre",
  [LABELS.EMAIL_ERROR_LABEL]: "El correo electrónico",
  [LABELS.PASSWORD_ERROR_LABEL]: "La contraseña",
  [LABELS.CONFIRM_PASSWORD_ERROR_LABEL]: "La constraseña de confirmación",
  [LABELS.LAST_ERROR_LABEL]: "El apelllido",
  [LABELS.PHONE_NUMBER_ERROR_LABEL]: "El número de teléfono",
  //LANDING PAGE
  [LABELS.LENDERS_LABEL]: "PRESTAMISTAS",
  [LABELS.HOMEBUYING_EVOLVED_LABEL]: "¡Compra de casas evolucionada!",
  [LABELS.LOOKING_FOR_PROP_LABEL]: "Buscando propiedades cerca de ti",
  [LABELS.HOMES_FOR_YOU_LABEL]: "Casas para ti en",
  [LABELS.NO_PROPERTIES_FOR_YOU_LABEL]: "No hay propiedades para ti en",
  [LABELS.START_NEW_SEARCH_LABEL]: "Iniciar una nueva búsqueda",
  [LABELS.VIEW_MORE_PROP_IN_LABEL]: "Ver más propiedades en",
  [LABELS.VIEW_FAVORITES_LABEL]: "Ver Favoritas",
  [LABELS.UNKNOW_PROPERTY_TYPE_LABEL]: "Tipo de propiedad desconocido",
  [LABELS.YOU_CAN_CONTINUE_SEARCHING_LABEL]: "Puedes seguir buscando en:",
  [LABELS.INFO_TITLE_ONE_LABEL]: "No más desplazamientos interminables",
  [LABELS.OFFER_LIKE_A_PRO_LABEL]: "Adapta tu oferta como un profesional",
  [LABELS.LENDER_APPROVAL_LABEL]:
    "Vincule la aprobación del préstamo de su prestamista",
  [LABELS.ALL_RIGHTS_RESERVED_LABEL]:
    "© Padzilly 2024. Todos los Derechos Reservados",
  [LABELS.GAIN_THE_UPPER_HAND_LABEL]: "Gana ventaja con",
  [LABELS.SEARCH_PRO_LANDING_LABEL]: "Search",
  [LABELS.OFFER_PRO_LANDING_LABEL]: "Offer",
  [LABELS.LOAN_LINK_LABEL]: "Loan",
  [LABELS.PRO_LABEL]: "Pro",
  [LABELS.LINK_LABEL]: "Link",
  [LABELS.AND_LABEL]: "y",
  [LABELS.FOLLOW_US_LABEL]: "Síguenos en:",
  [LABELS.IMPORTANT_LINKS_LABEL]: "Enlaces Importantes",
  [LABELS.ABOUT_LABEL]: "Acerca de",
  [LABELS.INVESTORS_LABEL]: "Inversores",
  [LABELS.CONTACT_LABEL]: "Contacto",
  [LABELS.SUPPORT_LABEL]: "Soporte",
  [LABELS.PARTNERS_LABEL]: "Socios",
  //END LANDING PAGE
  //SEARCH PRO
  [LABELS.PADZILLY_SEARCH_PRO_LABEL]: "Búsqueda Pro de Padzilly",
  [LABELS.MIN_DOWN_PAYMENT_CONV_LABEL]: "Depósito Mínimo Convencional:",
  [LABELS.MIN_DOWN_PAYMENT_FHA_LABEL]: "Depósito Mínimo FHA:",
  [LABELS.CONV_DOWN_PAYMENT_MESSAGE_LABEL]:
    "Depósito Mínimo Convencional es del 3%",
  [LABELS.FHA_DOWN_PAYMENT_MESSAGE_LABEL]: "Depósito Mínimo FHA es del 3.5%",
  [LABELS.CONV_DOWN_PAYMENT_MAX_MESSAGE_LABEL]:
    "Depósito Máximo Convencional es del 80%",
  [LABELS.FHA_DOWN_PAYMENT_MAX_MESSAGE_LABEL]: "Depósito Máximo FHA es del 80%",
  [LABELS.DOWN_PAYMENT_VALUES_SAVED_LABEL]:
    "Los valores de Depósito fueron guardados",
  [LABELS.SEARCH_PRO_LABEL]: "SearchPro",
  [LABELS.DONT_SHOW_VIDEO_LABEL]: "No mostrar el video otra vez",
  //MAP
  [LABELS.MAP_LABEL]: "Mapa",
  [LABELS.LIST_LABEL]: "Lista",
  //SCHOOLS
  [LABELS.NEARBY_SCHOOLS_LABEL]: "Escuelas Cercanas",
  [LABELS.PRIVATE_LABEL]: "Privada",
  [LABELS.ELEMENTARY_LABEL]: "Primaria",
  [LABELS.MIDDLE_LABEL]: "Secundaria",
  [LABELS.HIGH_LABEL]: "Preparatoria",
  [LABELS.DISTANCE_LABEL]: "Distancia",
  [LABELS.MORE_DETAILS_LABEL]: "Más Detalles",
  [LABELS.HIDE_LABEL]: "Ocultar",
  [LABELS.SCHOOL_WEBSITE_LABEL]: "Sitio Web escolar",
  [LABELS.AWAY_LABEL]: "",
  //TOUR
  [LABELS.BACK_LABEL]: "Atrás",
  [LABELS.LAST_LABEL]: "¡Entendido!",
  [LABELS.SKIP_LABEL]: "Saltar",
  [LABELS.NEXT_STEP_LABEL]: "Siguiente",
  [LABELS.PROPERTY_VALUES_LABEL]: "Valores de la propiedad",
  [LABELS.COMPARE_PROPERTIES_LABEL]: "Comparar Propiedades Guardadas",
  [LABELS.IMPORT_PROPERTIES_LABEL]: "Importar Propiedades de Otros Sitios",
  [LABELS.COMPARE_LOAN_TYPES_LABEL]: "Comparar tipos de Préstamos",
  [LABELS.SEARCH_PRO_OFFER_PRICE_TRIP_LABEL]: "Explore casas con precios de oferta ajustados",
  [LABELS.SEARCH_PRO_SELLER_TRIP_LABEL]: "Aplique las contribuciones del vendedor para reducir costos",
  [LABELS.SEARCH_PRO_DOWN_PAYMENT_LABEL]: "Ajuste su pago inicial para ver los ahorros",
  [LABELS.LOAN_ORIGINATOR_TRIP_LABEL]: "Desbloquee el poder de Padzilly vinculando su aprobación",
  [LABELS.INVESTMENT_PROFILE_TRIP_LABEL]: "Puede ajustar el presupuesto de compra de su vivienda aquí",
  //ADD LOAN(BUYER)
  [LABELS.CLICK_OR_DRAG_DOCUMENTS_LABEL]: "HAGA CLIC AQUÍ O ARRASTRE Y SUELTE LOS DOCUMENTOS DEL PRÉSTAMO",
  [LABELS.SAMPLE_LABEL]: "Ejemplo",
  [LABELS.SELECTED_FILES_LABEL]: "Archivos Seleccionados",
  [LABELS.PDF_SEARCHEABLE_LABEL]: "IMPORTANTE: Los archivos PDF deben ser LEGIBLES, donde el texto se puede leer digitalmente",
  [LABELS.ITEMIZATION_LABEL]: "Documento de desglose del monto financiado",
  [LABELS.FULL_URLA_LABEL]: "URLA completo",
  [LABELS.MATCH_FIELDS_LABEL]: "Campos coincidentes",
  [LABELS.UPLOAD_AND_VIEW_LABEL]: "Cargar documentos y ver resultados",
  [LABELS.LOAD_VALUES_LOAN_FORM_LABEL]: "Cargar valores en formulario de préstamo",
  [LABELS.TYPES_PDFS_LABEL]: "Tipos de PDFs",
  [LABELS.CATEGORIZED_PDFS_LABEL]: "Los documentos PDF se pueden clasificar en dos tipos diferentes",
  [LABELS.TRUE_OR_SEARCHABLE_LABEL]: "VERDADERO o LEGIBLE",
  [LABELS.IMAGE_PDF_LABEL]: "SÓLO IMAGEN",
  [LABELS.WARNING_LABEL]: "Advertencia",
  [LABELS.CONFIRM_LOAN_LABEL]: "Confirmar el uso de los datos cargados",
  [LABELS.LOAD_LOAN_FORM_LABEL]: "¿Quieres cargar los datos seleccionados en el formulario?",
  [LABELS.ADD_LOAN_LABEL]: "Agregar Préstamo",
  [LABELS.EDIT_LOAN_LABEL]: "Editar Préstamo",
  [LABELS.CREATE_LOAN_LABEL]: "Crear Nuevo Préstamo",
  [LABELS.BUYER_INFORMATION_LABEL]: "Información del Comprador",
  [LABELS.LOAN_PARAM_LABEL]: "Parámetros de calificación del préstamo",
  [LABELS.BORROWER_FIRST_NAME_LABEL]: "Nombre del prestatario",
  [LABELS.BORROWER_LAST_NAME_LABEL]: "Apellido del prestatario",
  [LABELS.BORROWER_EMAIL_LABEL]: "Correo electrónico del prestatario",
  [LABELS.BORROWER_MOBILE_PHONE_LABEL]: "Teléfono móvil del prestatario",
  [LABELS.COBORROWER_INFO_LABEL]: "Información del coprestatario",
  [LABELS.COBORROWER_FIRST_NAME_LABEL]: "Nombre del coprestatario",
  [LABELS.COBORROWER_LAST_NAME_LABEL]: "Apellido del coprestatario",
  [LABELS.ADDITIONAL_COBORROWER_LABEL]: "Coprestatarios adicionales",
  [LABELS.AVAIL_CASH_LABEL]: "Efectivo disponible todos los prestatarios",
  [LABELS.REALTOR_INFORMATION_LABEL]: "Información del agente inmobiliario",
  [LABELS.REALTOR_FIRST_NAME_LABEL]: "Nombre del agente inmobiliario",
  [LABELS.REALTOR_LAST_NAME_LABEL]: "Apellido del agente inmobiliario",
  [LABELS.REALTOR_EMAIL_LABEL]: "Correo electrónico del agente inmobiliario",
  [LABELS.REALTOR_MOBILE_PHONE_LABEL]: "Teléfono móvil del agente inmobiliario",
  [LABELS.UNIT_LABEL]: "Unidad",
  [LABELS.TOTAL_AVAILABLE_CASH_LABEL]: "Total de efectivo disponible",
  [LABELS.ADDITIONAL_COBORROWER_INCOME_LABEL]: "Ingresos adicionales del coprestatario",
  [LABELS.COBORROWER_MONTHLY_INCOME_LABEL]: "Ingreso mensual total del coprestatario",
  [LABELS.BORROWER_MONTHLY_INCOME_LABEL]: "Ingreso mensual total del prestatario",
  [LABELS.BORROWER_INFO_LABEL]: "Información del prestatario: haga clic en los campos que coincidan con el prestatario principal",
  [LABELS.PRIMARY_BORROWER_NAME_LABEL]: "Nombre del prestatario principal",
  [LABELS.PRIMARY_BORROWER_INCOME_LABEL]: "Ingresos del prestatario primario",
  [LABELS.BORROWER_ADDRESS_INFO_LABEL]: "Información de dirección del prestatario: haga clic en los campos que coincidan con el prestatario principal",
  [LABELS.COBORROWER_INFO_TWO_LABEL]: "Información del coprestatario: haga clic en los campos que coinciden con el coprestatario",
  [LABELS.COBORROWER_NAME_LABEL]: "Nombre del coprestatario",
  [LABELS.COBORROWER_INCOME_LABEL]: "Ingresos del coprestatario",
  [LABELS.ADDITIONAL_COBORROWER_INFO_LABEL]: "Información adicional del coprestatario: haga clic en los campos que coincidan con cualquier coprestatario adicional",
  [LABELS.SELECT_PRIMARY_INFO_LABEL]: "Seleccione la información de contacto principal",
  [LABELS.SELECT_LOAN_TYPE]: "Por favor seleccione el tipo de préstamo",
  [LABELS.HAS_SINGLE_PREMIUM_PMI_LABEL]: "Tiene PMI de prima única",
  [LABELS.BORROWER_HAS_USED_VA_LABEL]: "El prestatario HA UTILIZADO VA ANTES",
  [LABELS.REVIEW_LOAN_PARAMS_LABEL]: "Revisar los parámetros del préstamo",
  [LABELS.INTEREST_RATE_LABEL]: "Tasa de interés",
  [LABELS.LOAN_AMOUNT_LABEL]: "Monto del préstamo",
  [LABELS.BASE_LOAN_LABEL]: "Préstamo básico",
  [LABELS.LOAN_ORIGINATOR_FEE_LABEL]: "Tarifa de originación de préstamo",
  [LABELS.OR_LABEL]: "o",
  [LABELS.BROKER_FEE_LABEL]: "Tarifa de agente inmobiliario",
  [LABELS.ORIGINATION_CREDIT_LABEL]: "Crédito de originación",
  [LABELS.LOAN_DISCOUNT_POINTS_LABEL]: "Puntos de descuento para préstamos",
  [LABELS.INTEREST_TO_FIRST_PAYMENT]: "Intereses al primer pago",
  [LABELS.DAYS_LABEL]: "días",
  [LABELS.CONVENTIONAL_SINGLE_PREMIUM_LABEL]: "PMI de prima única convencional",
  [LABELS.FHA_MORTGAGE_INS_PREMIUM_LABEL]: "Prima del seguro hipotecario FHA UFMIP",
  [LABELS.VA_FUNDING_FEE_LABEL]: "Tarifa de financiación de VA",
  [LABELS.MORTGAGE_INS_RESERVES_LABEL]: "Reservas de seguros hipotecarios",
  [LABELS.MTHS_LABEL]: "mths",
  [LABELS.HAZARD_INS_PREMIUM_MONTHLY_LABEL]: "Prima del seguro contra riesgos - mensual",
  [LABELS.HAZARD_INS_RESERVES_LABEL]: "Reservas de seguros contra riesgos",
  [LABELS.COUNTY_PROP_TAX_MONTHLY_LABEL]: "Impuesto de propiedad del condado - mensual",
  [LABELS.COUNTY_PROP_TAX_LABEL]: "Reservas de impuestos de apoyo del condado",
  [LABELS.FLOOD_INSURANCE_PREMIUM_LABEL]: "Prima del seguro contra inundaciones",
  [LABELS.FLOOD_INSURANCE_RESERVE_LABEL]: "Reserva de seguro contra inundaciones",
  [LABELS.OTHER_INS_PREMIUM_LABEL]: "Otra prima de seguro",
  [LABELS.OTHER_INS_RESERVE_LABEL]: "Reserva de otros seguros",
  [LABELS.LENDER_TITLE_INS_LABEL]: "Seguro de título del prestamista",
  [LABELS.CITY_COUNTY_STAMPS_LABEL]: "Ciudad / Condado / Sellos de Hipoteca",
  [LABELS.STATE_TAX_STAMPS_LABEL]: "Impuesto estatal / Sellos sobre escrituras",
  [LABELS.TOTAL_AMT_PAID_LABEL]: "AMT TOTAL PAGADO A OTROS EN SU NOMBRE",
  [LABELS.TOTAL_PREPAID_LABEL]: "CARGO TOTAL POR FINANCIAMIENTO PREPAGO",
  [LABELS.LOAN_TERM_LABEL]: "Plazo del préstamo (no menos de 120 meses)",
  [LABELS.MONTHLY_PMI_PREMIUM_LABEL]: "Prima mensual del PMI",
  [LABELS.TOTAL_PROPOSED_MONTHLY_PAYMENT_LABEL]: "Pago mensual total propuesto",
  [LABELS.SALES_CONTRACT_PRICE_LABEL]: "PRECIO DEL CONTRATO DE VENTA",
  [LABELS.ADD_THIRD_PARTY_LABEL]: "Agregar créditos de terceros al comprador",
  [LABELS.OTHER_CREDITS_LABEL]: "M. Otros créditos",
  [LABELS.COMPLETE_FOLLOWING_FIELDS_LABEL]: "Complete los siguientes campos del Documento de Desglose del Monto Financiado",
  [LABELS.OUR_ORIGINATION_SALES_LABEL]: "NUESTRAS VENTAS DE ORIGINACIÓN INCLUYEN",
  [LABELS.YOUR_CREDIT_OR_CHARGE_LABEL]: "TU CRÉDITO O CARGO (PUNTOS)",
  [LABELS.PREPAIDS_LABEL]: "PREPAGOS",
  [LABELS.SETTLEMENT_CLOSING_COSTS_LABEL]: "COSTOS DE LIQUIDACIÓN O CIERRE",
  [LABELS.TRANSFER_TAXES_INCLUDES_LABEL]: "IMPUESTOS DE TRANSFERENCIA INCLUYE",
  [LABELS.THIS_FIELD_ONLY_TEXTS_LABEL]: "Este campo acepta solo texto",
  [LABELS.THIS_FIELD_ONLY_NUMBERS_LABEL]: "Este campo solo acepta números",
  [LABELS.ENTER_VALID_EMAIL_LABEL]: "Por favor, introduce una dirección de correo electrónico válida",
  [LABELS.ENTER_VALID_PHONE_NUMBER_LABEL]: "Por favor ingrese un número de teléfono válido. ej: xxx-xxx-xxxx",
  [LABELS.BIGGER_THAN_ZERO_LABEL]: "Este valor debe ser mayor que cero",
  [LABELS.ENTER_VALID_ZIP_CODE_LABEL]: "Por favor, ingresa un código postal válido",
  [LABELS.ENTER_VALID_STATE_LABEL]: "Por favor, ingresa un estado válido. Ej: FL",
}; 
