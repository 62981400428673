import axios from "axios";
import {
    CREATE_INVESTMENT_PROFILE,
    GET_INVESTMENT_PROFILE,
    UPDATE_INVESTMENT_PROFILE
} from "../endpoints/endpoints";

export async function getInvestmentProfile(profile_token) {

    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {};

        const result = await axios.get(GET_INVESTMENT_PROFILE, {
            headers,
        });

        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function updateInvestmentProfile(invProfile, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {};

        const result = await axios.put(UPDATE_INVESTMENT_PROFILE, invProfile, {
            headers,
        });

        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function createInvestmentProfile(paymentLimit, fundsLimit, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {};

        const result = await axios.post(CREATE_INVESTMENT_PROFILE, {
            paymentLimit: paymentLimit,
            investmentAmount: fundsLimit
        }, {
            headers,
        });

        return result.data;
    } catch (error) {
        throw error;
    }
}


export async function createInvestmentProfileByLoanId(invProfile, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {};
        const result = await axios.post(CREATE_INVESTMENT_PROFILE, {
            loanOriginatorId: invProfile.loanOriginatorId,
            loanId: invProfile.loanId,
        }, {
            headers,
        });

        return result.data;
    } catch (error) {
        throw error;
    }
}