import { useState, useCallback } from 'react';

export const useError = () => {
    const [error, setError] = useState(null);

    const setErrorMessage = useCallback((message, type = null) => {
        setError({ message: message, type: type ?? "error" });
    }, []);

    const clearError = useCallback(() => {
        setTimeout(() => { setError(null); }, 500);
    }, []);

    return {
        error,
        setErrorMessage,
        clearError,
    };
};