import { Box } from "@mui/material";
import Joyride from 'react-joyride';
import { useTranslation } from 'react-i18next';
import {
    BACK_LABEL,
    CLOSE_LABEL,
    COMPARE_LOAN_TYPES_LABEL,
    LAST_LABEL,
    NEXT_STEP_LABEL,
    SEARCH_PRO_DOWN_PAYMENT_LABEL,
    SEARCH_PRO_OFFER_PRICE_TRIP_LABEL,
    SEARCH_PRO_SELLER_TRIP_LABEL,
    SKIP_LABEL
} from "../../i18n/i18nLabel";
import {
    TRIP_SEARCH_PRO_DOWN_PAYMENT_TEXT,
    TRIP_SEARCH_PRO_LOANS_TEXT,
    TRIP_SEARCH_PRO_OFFER_PRICE_TEXT,
    TRIP_SEARCH_PRO_SELLER_RED_TEXT,
    TRIP_SEARCH_PRO_SELLER_TEXT
} from "../../i18n/i18nText";
import { useEffect, useState } from "react";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../constants/constants";
import { useStore } from "../../hooks/store/store";
import { GET_INVESTMENT_PROFILE_LOAN } from "../../constants/store/getters";

export default function SearchProTrip({
    onClose,
    run,
    continuous,
    scrollToFirstStep,
    showSkipButton,
}) {

    const { t } = useTranslation();

    const [stepIndex, setStepIndex] = useState(null);

    const { state } = useStore();

    const steps = [
        !state[GET_INVESTMENT_PROFILE_LOAN] && {
            title: t(COMPARE_LOAN_TYPES_LABEL),
            target: '#searchProLoanTypes',
            content: t(TRIP_SEARCH_PRO_LOANS_TEXT),
            disableBeacon: true,
            placement: 'auto',
        },
        {
            title: t(SEARCH_PRO_OFFER_PRICE_TRIP_LABEL),
            target: '#searchProOfferPrice',
            content: t(TRIP_SEARCH_PRO_OFFER_PRICE_TEXT),
            disableBeacon: true,
            placement: 'auto',
        },
        {
            title: t(SEARCH_PRO_SELLER_TRIP_LABEL),
            target: '#searchProSellerRebate',
            content: <Box>
                <span>{t(TRIP_SEARCH_PRO_SELLER_TEXT)}</span>
                <span style={{ color: SECONDARY_COLOR }}>{t(TRIP_SEARCH_PRO_SELLER_RED_TEXT)}</span>
            </Box>,
            disableBeacon: true,
            placement: 'auto',
        },
        {
            title: t(SEARCH_PRO_DOWN_PAYMENT_LABEL),
            target: '#searchProDownPayment',
            content: t(TRIP_SEARCH_PRO_DOWN_PAYMENT_TEXT),
            disableBeacon: true,
            placement: 'auto',
        },
    ].filter(Boolean);

    useEffect(() => {
        const step0 = document.querySelector('#react-joyride-step-0');

        if (step0) {
            step0.style.zIndex = '9999';
            step0.style.position = 'relative';
        }

        const step1 = document.querySelector('#react-joyride-step-1');

        if (step1) {
            step1.style.zIndex = '9999';
            step1.style.position = 'relative';
        }
        const step2 = document.querySelector('#react-joyride-step-2');

        if (step2) {
            step2.style.zIndex = '9999';
            step2.style.position = 'relative';
        }

        const step3 = document.querySelector('#react-joyride-step-3');

        if (step3) {
            step3.style.zIndex = '9999';
            step3.style.position = 'relative';
        }

    }, [
        stepIndex
    ]);

    return (
        <Box>
            <Joyride
                steps={steps}
                run={run}
                continuous={continuous}
                scrollToFirstStep={scrollToFirstStep}
                showSkipButton={showSkipButton}
                callback={(data) => {
                    setStepIndex(data.index);
                    if (data.status === 'finished' || data.status === 'skipped' || data.action === 'close') {
                        onClose();
                    }
                }}
                styles={{
                    options: {
                        primaryColor: PRIMARY_COLOR,
                    },
                    tooltip: {
                        fontSize: '.69rem',
                        padding: '10px',
                        zIndex: 100,
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 100
                    },
                    tooltipTitle: {
                        fontSize: ".8rem",
                        marginRight: "30px"
                    },
                    buttonSkip: {
                        fontSize: ".7rem"
                    },
                    buttonBack: {
                        fontSize: ".7rem"
                    },
                    buttonNext: {
                        fontSize: ".7rem"
                    },
                    buttonClose: {
                        fontSize: ".7rem",
                    },
                    tooltipContent: {
                        padding: "0px",
                        marginTop: "8px"
                    }
                }}
                locale={
                    {
                        back: t(BACK_LABEL),
                        close: t(CLOSE_LABEL),
                        last: t(LAST_LABEL),
                        next: t(NEXT_STEP_LABEL),
                        skip: t(SKIP_LABEL)
                    }
                }
            />
        </Box>
    );
}