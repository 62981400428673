import Backdrop from '@mui/material/Backdrop';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LOADING_LABEL } from '../../i18n/i18nLabel';
import LogoNoWhite from '../Images/LogoNoWhite';
import { PRIMARY_COLOR } from '../../constants/constants';
import { useMemo } from 'react';

export default function BaseLoading({ loading }) {

    const { t } = useTranslation();

    const baseLoading = useMemo(() => {
        return (<Backdrop
            sx={{
                color: 'black',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: "white"
            }}
            open={loading}
        >
            <Box
                sx={{
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CircularProgress style={{ color: PRIMARY_COLOR }} size={30} />
                <p
                    style={{ fontWeight: "bold" }}
                >
                    {t(LOADING_LABEL)}
                </p>
                <LogoNoWhite />
            </Box>
        </Backdrop>);
    }, [loading]);

    return baseLoading;
}