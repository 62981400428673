import TextField from '@mui/material/TextField';
import { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import EmailIcon from '@mui/icons-material/Email';
import { primaryTheme } from '../themes/themes';
import { ThemeProvider } from '@mui/material/styles';

export default function BaseInput({
    value,
    label,
    required,
    error,
    type,
    placeholder,
    style,
    variant,
    onInputChange,
    startIcon,
    onBlur,
    name
}) {

    const [val, setVal] = useState(value ?? "");

    const [showPassword, setShowPassword] = useState(type === "password" ? false : true);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <ThemeProvider theme={primaryTheme}>
            <TextField
                name={name}
                label={label}
                type={showPassword ? 'text' : 'password'}
                color='primary'
                error={error ? true : false}
                required={required}
                placeholder={placeholder ?? null}
                autoComplete='off'
                size="small"
                style={style ?? null}
                variant={variant ?? "standard"}
                helperText={error || ' '}
                value={val}
                onChange={(event) => {
                    setVal(event.target.value);
                    if (onInputChange) {
                        onInputChange(event.target.value);
                    }
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        {type === "password"
                            ? <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            : null
                        }
                    </InputAdornment>,
                    startAdornment: startIcon
                        ?
                        (<InputAdornment position="start">
                            {startIcon === "person"
                                ? <PersonIcon />
                                : startIcon === "phone"
                                    ? <StayCurrentPortraitIcon />
                                    : startIcon === "email"
                                        ? <EmailIcon />
                                        : null
                            }
                        </InputAdornment>)
                        : null
                }}
                onBlur={() => { if (onBlur) { onBlur() } }}
                FormHelperTextProps={{
                    style: { minHeight: '5px' },
                }}
            />
        </ThemeProvider>
    );
}