import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

export default function BaseDialogHeader({ children, title, close }) {

    return (
        <DialogTitle style={{ padding: 0, borderBottom: "solid 1px rgba(0, 0, 0, .05)" }}>
            <Box className='dialogHeader'>
                <Box>
                    {children ?? title}
                </Box>
                {
                    close
                        ? (<IconButton
                            onClick={(e) => close(e)}
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                            }}
                            size='small'
                        >
                            <CloseIcon fontSize='small' />
                        </IconButton>)
                        : null
                }
            </Box>
        </DialogTitle>
    );
}