export const HELP_URL = "https://support.padzilly.net/articles/127334-b1124-007-how-to-set-up-your-buyer-investment-profile-bip";
export const HELP_URL_ES = "https://ayuda.padzilly.net/articles/127716-s1124-007-como-configurar-tu-perfil-de-inversion-del-comprador-bip";
export const CONNECTED_LENDER = "https://support.padzilly.net/articles/127176-b1124-003-what-if-my-lender-cant-subscribe-or-no-longer-subscribes";
export const CONNECTED_LENDER_ES = "https://ayuda.padzilly.net/articles/127719-s1124-003-que-hago-si-mi-prestamista-no-puede-suscribirse-o-ya-no-se-suscribe";
export const TERMS_CONDITIONS = "https://padzilly.com/terms.html";
export const PRIVACY_POLICY = "https://padzilly.com/privacy.html";
export const PAYMENT_EXPLAIN = "https://support.padzilly.net/articles/127178-b1124-004-what-makes-up-the-total-mortgage-payment";
export const PAYMENT_EXPLAIN_ES = "https://ayuda.padzilly.net/articles/127720-s1124-004-que-compone-el-pago-total-de-la-hipoteca";
export const HOW_ADJUST_SEARCH_PRO = "https://support.padzilly.net/articles/127341-b1124-006-how-to-adjust-offer-price-down-payment-and-seller-contributions-in-offerpro";
export const HOW_ADJUST_SEARCH_PRO_ES = "https://ayuda.padzilly.net/articles/127722-s1124-006-como-ajustar-el-precio-de-la-oferta-el-pago-inicial-y-las-contribuciones-del-vendedor-en-offerpro";
export const ZERO_HOA_TAX = "https://support.padzilly.net/articles/127163-b1124-026-missing-hoa-fee-or-property-taxes";
export const ZERO_HOA_TAX_ES = "https://ayuda.padzilly.net/articles/128050-s1124-026-no-hoa-o-taxes";
export const LICENSING = "https://www.homeservices.com/licensing";
export const COVERAGE = "https://support.padzilly.net/articles/127472-b1124-031-where-to-check-for-mls-coverage-b103";
export const COVERAGE_ES = "https://ayuda.padzilly.net/articles/128375-s1124-031-donde-consultar-la-cobertura-de-mls-b103";
export const OFFER_PRO = "https://ayuda.padzilly.net/articles/128375-s1124-031-donde-consultar-la-cobertura-de-mls-b103";
export const OFFER_PRO_ES = "https://ayuda.padzilly.net/articles/127721-s1124-005-como-utilizar-offerpro";
export const ZILLOW = "https://www.zillow.com/";
export const REALTOR = "https://www.realtor.com/";
export const APP_STORE =
  "https://apps.apple.com/us/app/padzilly-homes/id6472548267";
export const GOOGLE_PLAY =
  "https://play.google.com/store/apps/details?id=com.padzilly.homes&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
export const PADZILLY_SWIFTMATCH = "https://support.padzilly.net/articles/127162-b1124-002-how-to-use-searchpro";
export const PADZILLY_SWIFTMATCH_ES = "https://ayuda.padzilly.net/articles/127718-s1124-002-como-utilizar-searchpro";
export const FACEBOOK_LINK =
  "https://www.facebook.com/profile.php?id=61554671340119";
export const INSTAGRAM_LINK = "https://www.instagram.com/padzillyhomes/";
export const LINKEDIN_LINK =
  "https://www.linkedin.com/company/padzilly-homes/?viewAsMember=true";
export const CONTACT_EN = "https://support.padzilly.net/contact";
export const CONTACT_ES = "https://ayuda.padzilly.net/contact";
export const ABOUT = "https://help.padzilly.info/about";
export const INVESTORS = "https://help.padzilly.info/investors";
export const LENDERS = "https://help.padzilly.info/index";
export const LOAN_LIMITS = "https://support.padzilly.net/articles/128061-b1124-025-loan-limits";
export const LOAN_LIMITS_ES = "https://ayuda.padzilly.net/articles/128062-s1124-025-limites-de-prestamo";
export const SUPPORT_ESPANOL = "https://ayuda.padzilly.net/";
export const SUPPORT_ENGLISH = "https://support.padzilly.net/";
export const MIN_TAX = "https://support.padzilly.net/articles/128123-b1124-010-alternate-minimum-property-tax";
export const MIN_TAX_ES = "https://ayuda.padzilly.net/articles/128124-s1124-010-impuesto-minimo-alternativo-sobre-la-propiedad-en-padzilly";
export const PARTNER_PAGE = "https://padzilly.com/lenders";
export const IMPORT_PROPERTIES_FROM_OTHER_SITES = "https://support.padzilly.net/articles/127337-b1124-019-how-to-import-properties-from-other-real-estate-platforms";
export const IMPORT_PROPERTIES_FROM_OTHER_SITES_ES = "https://ayuda.padzilly.net/articles/128364-s1124-019-como-importar-propiedades-desde-otras-plataformas-inmobiliarias";