import { TEXT } from "../../constants/constants"
import * as TEXTS from "../i18nText"

export default {
    [TEXTS.DISCLAIMER_TITLE_TEXT]: "MLS Data provided under licensing by the",
    [TEXTS.DISCLAIMER_TITLE_LINK_TEXT]: "National Brokerage Network",
    [TEXTS.PAYMENT_BREAKDOWN_TITLE_TEXT]: "This is not an advertisement for a mortage loan or an offer to extend credit.",
    [TEXTS.PAYMENT_BREAKDOWN_FIRST_TEXT]: "The estimated payment is based on assumptions of insurance, taxes and a sample interest rate of 7.250% we used at the time we qualified you. These factors may be different at time of closing.",
    [TEXTS.PAYMENT_BREAKDOWN_SECOND_TEXT]: "The estimated payment is based on assumptions of insurance, taxes and the ",
    [TEXTS.PAYMENT_BREAKDOWN_SECOND_STRONG_TEXT]: "loan program you are qualified for. These factors may be different at time of closing.",
    [TEXTS.IMPORT_PROP_ONE_TEXT]: "You can import a property from",
    [TEXTS.IMPORT_PROP_TWO_TEXT]: "by providing the property URL",
    [TEXTS.IMPORT_PROP_O_TEXT]: "or",
    [TEXTS.THERE_IS_NO_LOAN_O_TEXT]: "Click here to invite your lender to upload your loan approval and unlock all Padzilly features, including the seller rebate/contribution",
    [TEXTS.PLEASE_AUTHENTICATE_TEXT]: "Sign up to save your favorite properties and searches and invite your lender to upload your loan approval",
    [TEXTS.CURRENTLY_AS_GUEST_TEXT]: "You are currently browsing as a guest",
    [TEXTS.AMOUNTS_BELOW_TEXT]: "Amounts below are from your loan approval",
    [TEXTS.REMOVE_LOAN_ORIGINATOR_TEXT]: "Are you sure you want to remove associated Loan Originator?",
    [TEXTS.DO_NOT_HAVE_LOAN_ORIGINATOR_TEXT]: "Please, complete the fields below",
    [TEXTS.LOAN_OFFICER_CONNECTED_TEXT]: "Your loan officer is connected to Padzilly",
    [TEXTS.WE_ARE_CONTACTING_TEXT]: "We are contacting them to upload your loan approval profile right away",
    [TEXTS.GETTING_PROPS_TEXT]: "Getting the properties, please hold on",
    [TEXTS.GETTING_DETAILS_TEXT]: "Getting the property, please hold on",
    [TEXTS.FORGOT_PASS_OTP_TEXT]: "We will send you an email with a One-Time Password (OTP) to reset your password.",
    [TEXTS.VERIFICATION_TEXT]: "Please enter the verification code we sent to your email address",
    [TEXTS.CHECK_SPAM_TEXT]: "Check your spam ok junk folder if it is not in your inbox. This can take 2-3 minutes",
    [TEXTS.LETS_CREATE_PADZILLY_SWIFTMATCH_TEXT]: "Let's create your home search profile on Padzilly SearchPro!",
    [TEXTS.CHOOSE_PRE_APPROVED_TEXT]: "Choose this if you have a current qualification or approval from a lender",
    [TEXTS.CHOOSE_NOT_APPROVED_TEXT]: "Choose this if you don't have an approval, but want to start smart searching now",
    [TEXTS.CREATE_SWIFTMATCH_PROFILE_TEXT]: "No problem! Let's create your SearchPro profile",
    [TEXTS.FOR_BEST_RESULTS_TEXT]: "For the best results, a lender pre-qualification connected to Padzilly is recommended",
    [TEXTS.IN_THE_MEANTIME_TEXT]: "In the meantime, let's try SearchPro!",
    [TEXTS.WE_WILL_REPLACE_TEXT]: "We will replace this with your loan data once it is uploaded",
    [TEXTS.WE_WILL_CHECK_LOAN_OFFICER_TEXT]: "We will check to see if your loan officer is already connected to Padzilly",
    [TEXTS.GREAT_NEWS_TEXT]: "Great news! Your loan officer is connected to Padzilly",
    [TEXTS.LOAN_OFFICER_NOT_CONNECTED_TEXT]: "It looks like your loan officer is not connected to Padzilly yet",
    [TEXTS.WE_ARE_REACHING_OUT_TEXT]: "We are reaching out to them so they can sign up and make your Home shopping journey easier and faster",
    [TEXTS.GREAT_NEWS_TWO_TEXT]: "Great news! Your Lender is connected to Padzilly and we found your loan approval",
    [TEXTS.CONTINUE_SEARCH_TEXT]: "You can go to Homes and continue your search",
    [TEXTS.ENTER_INFO_FOR_SEARCH_TEXT]: "Please, enter a city, zip code or address to search for",
    [TEXTS.NO_MATCHING_PROPERTIES_TEXT]: "It looks like we couldn't find any properties for your search criteria, possibly because we're still expanding coverage!",
    [TEXTS.CHANGE_PROPERTY_TYPE_TEXT]: "Change the property type, rooms, and feature filters above",
    [TEXTS.FIND_IN_ZILLOW_OR_REALTOR_TEXT]: "If you can not find a specific property you can search on Zillow or Realtor.com and share with",
    [TEXTS.CHECK_MLS_TEXT]: "Alternate view: See list of active counties. ",
    [TEXTS.PROBLEM_LOADING_PROPERTIES_TEXT]: "There was a problem loading the properties",
    [TEXTS.CHECK_YOUR_CONNECTION_TEXT]: "Please check your connection and try again",
    [TEXTS.WE_COULDNT_FIND_PROP_TEXT]: "Sorry, we couldn't find the property",
    [TEXTS.FAVORITES_GUEST_TEXT]: "An account is required to access your favorite property listing. Please sign up to create an account, or log in if you have an account",
    [TEXTS.OFFER_PRO_BACKDROP_TEXT]: "Use OfferPro to qualify or secure the best deal by adjusting price and other factors",
    [TEXTS.VERIFY_MONTHLY_ASSOCIATION_TEXT]: "Please verify the monthly association fee and annual property tax amount to ensurance an accurate approval",
    [TEXTS.NOT_SAVED_PROPERTIES_TEXT]: "You have not saved any property as favorite",
    [TEXTS.EXPLORE_AND_SAVE_PROPERTIES_TEXT]: "Explore and save your preferred properties",
    [TEXTS.YOU_APPEAR_QUALIFIED_TEXT]: "You appear qualified for this home!",
    [TEXTS.CASH_NEEDED_TEXT]: "You may qualify it the Cash Needed is lowered. Try adjusting below",
    [TEXTS.PAYMENT_NEEDED_TEXT]: "You may qualify it the Payment is lowered. Try adjusting below",
    [TEXTS.PAYMENT_AND_CASH_NEEDED_TEXT]: "You may qualify it the Payment and Cash Needed are lowered. Try adjusting below",
    [TEXTS.MUST_BE_GREATER_THAN_TEXT]: "must be greater than 0",
    [TEXTS.MORTGAGE_PAYMENT_ERROR_TEXT]: "limit must be lower than Funds limit",
    [TEXTS.VALID_URL_TO_IMPORT_ERROR_TEXT]: "must be a valid Realtor or Zillow Link",
    [TEXTS.INVALID_EMAIL_ERROR_TEXT]: "Invalid email, ex: joe@padzilly.com",
    [TEXTS.ONLY_LETTERS_ERROR_TEXT]: "must have only letters",
    [TEXTS.NO_MLO_FOUND_ERROR_TEXT]: "No Loan Officer was found with the provided email, we have sent an invitation to the email you provided for your lender to join Padzilly",
    [TEXTS.MLO_ALREADY_INVITED]: "We have already sent an invitation to {{0}}  using your email.",
    [TEXTS.MLO_SAME_EMAIL_ERROR_TEXT]: "should not be the same as the current associated MLO email",
    [TEXTS.PASSWORDS_MUST_MATCH_ERROR_TEXT]: "Passwords must match",
    [TEXTS.FIRST_NAME_ERROR_TEXT]: "Invalid First Name, ex: Joe",
    [TEXTS.LAST_NAME_ERROR_TEXT]: "Invalid Last Name, ex: Smith",
    [TEXTS.FULL_NAME_ERROR_TEXT]: "Invalid Full Name, ex: Joe Smith",
    [TEXTS.MLO_WAS_REMOVED_ERROR_TEXT]: "The MLO was removed",
    [TEXTS.NO_INVESTMENT_PROFILE_TEXT]: "There is no investment profile, if you have an account you can log in here",
    [TEXTS.CONTINUE_AS_GUEST_TEXT]: "If you want to continue as a guest you can create an investment profile here",
    [TEXTS.NO_SAVED_SEARCHES_TEXT]: "You do not have saved searches yet",
    [TEXTS.NO_SAVED_SEARCHES_SUBTITLE_TEXT]: "You can go to Homes and save searches to run them at any time and receive notifications if you want",
    [TEXTS.DELETE_SEARCH_TEXT]: "Do you want to delete the saved search?",
    [TEXTS.INVESTMENT_PROFILE_NO_LOAN_TEXT]: "This box contains the user's editable payment and cash limits (Buyer Investment Profile - BIP)",
    [TEXTS.SEARCH_PRO_TEXT]: "This box shows the three SearchPro sliders for Conventional and/or FHA loans. You can adjust and save your preferences",
    [TEXTS.SUSCRIBE_TEXT]: "If your loan officer doesn't join Padzilly, you can get the same benefits by purchasing a subscription for just $19.95 as a one-time payment",
    [TEXTS.SUBSCRIPTION_DISCLAIMER_TEXT]: "Enjoy all the benefits even if your loan officer doesn't join Padzilly. All for a one-time fee of $19.95. Transactions are made with Stripe, Padzilly does not store any data about your payment method",
    [TEXTS.TAXES_BREAKDOWN_PAYMENT_OFFER_PRO_TEXT]: "On screen display when using the alternante min tax.  Alternate min. tax used for qualifying.",
    [TEXTS.CANT_INVITE_PADZILLY_MLO_TEXT]: "This lender cannot be invited",
    [TEXTS.MLO_NOT_ACTIVE_TEXT]: "This lender despite being connected to Padzilly is not active, therefore you cannot link it as your lender, you can invite another lender or contact support for more information",
    [TEXTS.GOOD_NEWS_TEXT]: "Good News: We're rolling out nationwide coverage this January!",
    [TEXTS.CHECK_MAP_TEXT]: "Check our interactive map below for current coverage in your area",
    [TEXTS.HAVE_COVERAGE_TEXT]: "If we have coverage in your area, try adjusting your search filters for rooms, features, property type, etc.",
    [TEXTS.HAVE_NOT_COVERAGE_TEXT]: "If we don't have coverage in your area yet, you can still search and import properties from other real estate sites. ",
    //LANDING PAGE
    [TEXTS.WE_DIDNT_FIND_PROPS_TEXT]: "We didn't find any properties near you, you can start a new search",
    [TEXTS.INFO_TEXT_ONE_TEXT]: "Only Padzilly quickly zeros in on your home by using your desired monthly payment and cash available to filter all homes and find those that fit your budget.",
    [TEXTS.INFO_ONE_POINT_ONE_TEXT]: "Set your own payment and investment limit, or upload your lender loan qualification",
    [TEXTS.INFO_ONE_POINT_TWO_TEXT]: "View only homes that fit your requirements or lender pre-approval",
    [TEXTS.INFO_ONE_POINT_THREE_TEXT]: "Highly accurate payment and cash figures",
    [TEXTS.OFFER_LIKE_A_PRO_TEXT]: "Padzilly is the only platform that helps you structure your offer to get the best deal and fit a property to your loan approval.",
    [TEXTS.INFO_TWO_POINT_ONE_TEXT]: "Adjust price, seller concession and down payment in seconds ",
    [TEXTS.INFO_TWO_POINT_TWO_TEXT]: "Try infinite financial scenarios with ease",
    [TEXTS.INFO_TWO_POINT_THREE_TEXT]: "Structure your best deal and make offers with confidence, just like the pros do",
    [TEXTS.LOAN_APPROVAL_TEXT]: "Ask your lender to upload your loan approval and Padzilly will ensure every listing matches your pre-approved loan",
    [TEXTS.INFO_THREE_POINT_ONE_TEXT]: "Eliminate the guesswork",
    [TEXTS.INFO_THREE_POINT_TWO_TEXT]: "View only homes you truly qualify for",
    [TEXTS.INFO_THREE_POINT_THREE_TEXT]: "Lender level payment and cash figures",
    [TEXTS.INFO_THREE_POINT_FOUR_TEXT]: "Get instant property approval letters and offer terms",
    [TEXTS.FOOTER_TEXT]: "For a better experience you can download the app from Google Play or AppStore",
    [TEXTS.LENDER_NOT_ACTIVE_TEXT]: "Your lender is not active, please contact them",
    //SCHOOLS
    [TEXTS.SCHOOLS_DISCLAIMER_TEXT]: "The GreatSchools Rating helps parents compare schools within a state based on a variety of school quality indicators and provides a helpful picture of how effectively each school serves all of its students. Ratings are on a scale of 1 (below average) to 10 (above average) and can include test scores, college readiness, academic progress, advanced courses, equity, discipline and attendance data. We also advise parents to visit schools, consider other information on school performance and programs, and consider family needs as part of the school selection process",
    [TEXTS.SELLER_REBATE_ONLY_LAP_TEXT]: "Seller rebate is only available once your lender uploads your approval or prequalification. Invite your lender to upload now and unlock this feature",
    //LOADING PROPERTIES
    [TEXTS.LOADING_PROPERTIES_ONE_TEXT]: "Crunching numbers like a chef dicing veggies... Your recipe for the perfect home is almost ready! Please hold on",
    [TEXTS.LOADING_PROPERTIES_TWO_TEXT]: "Crunching numbers like a DJ mixing beats... The perfect home track is about to drop! Please hold on",
    [TEXTS.LOADING_PROPERTIES_THREE_TEXT]: "Crunching numbers like a squirrel stashing acorns... Gathering just the right homes for you! Please hold on",
    [TEXTS.LOADING_PROPERTIES_FOUR_TEXT]: "Crunching numbers like a barista frothing milk... Your custom home brew is almost done! Please hold on",
    [TEXTS.LOADING_PROPERTIES_FIVE_TEXT]: "Crunching numbers like a puzzle master... Piecing together your perfect home match! Please hold on",
    [TEXTS.LOADING_PROPERTIES_SIX_TEXT]: "Crunching numbers like a writer finding the perfect word... Your home story is nearly written! Please hold on",
    [TEXTS.LOADING_PROPERTIES_SEVEN_TEXT]: "Crunching numbers like a sculptor chiseling marble... Your masterpiece home is taking shape! Please hold on",
    [TEXTS.LOADING_PROPERTIES_EIGHT_TEXT]: "Crunching numbers like a baker kneading dough... The perfect home is about to rise! Please hold on",
    [TEXTS.LOADING_PROPERTIES_NINE_TEXT]: "Crunching numbers like a gamer leveling up... Your home victory is on the horizon! Please hold on",
    //TRIP
    [TEXTS.TRIP_HOME_SEARCH_GOALS_PAYMENT_TEXT]: "Your target monthly mortgage payment help us show you homes you can afford based on your limits, before you load an actual lender approval. Not sure? Think how much you're comfortable paying each month for your home, including principal, interest, taxes and insurance. You can always adjust this later",
    [TEXTS.TRIP_HOME_SEARCH_GOALS_CASH_TEXT]: "This is the total amount of money you have available to cover your down payment, closing costs and other upfront expenses. If you're unsure, enter the amount you've saved or plan to use for you home purchase. We use this to show you homes that fit within your budget",
    [TEXTS.TRIP_PAYMENT_BTN_TEXT]: "Press this button to only show homes with monthly payments that fall within your target payment limit",
    [TEXTS.TRIP_CASH_BTN_TEXT]: "Press this button to only show homes that fit within your available cash limit",
    [TEXTS.TRIP_PAYMENT_CASH_BTN_TEXT]: "By pressing both the 'Filter by Payment' and 'Filter by Cash Needed' buttons, you'll only see homes that fit completely within both your payment and cash limits",
    [TEXTS.TRIP_CONVENTIONAL_LOAN_TEXT]: "View estimated monthly payment and cash needed as if you were financing this home with a Conventional Loan. This estimate uses typical down payment and interest rates for conventional loans",
    [TEXTS.TRIP_FHA_LOAN_TEXT]: "View estimated monthly payment and cash needed as if you were financing this home with an FHA Loan. This estimate includes FHA-specific terms, like lower down payments and Mortgage Insurance Premium (MIP)",
    [TEXTS.TRIP_SEARCH_PRO_TEXT]: "Use SearchPro to apply advanced filters and find homes that fit your financial and loan parameters, including payment and cash needed limits. Adjust the sale price, seller contribution, or increase your down payment to refine your search results",
    [TEXTS.TRIP_BED_TEXT]: "Quickly filter homes by the number of bedrooms. See homes that match your space and lifestyle needs",
    [TEXTS.TRIP_BATH_TEXT]: "Quickly filter homes by the number of bathrooms. Find properties with the right number of bathrooms for your comfort",
    [TEXTS.TRIP_SAVE_SEARCH_TEXT]: "Save your favorite searches to run them again at any time and receive alerts of properties perfect for you",
    [TEXTS.TRIP_FILTERS_TEXT]: "Use advanced filters to narrow your search by lot size, year built, home size, property type, and more. Customize your search to find the perfect home",
    [TEXTS.TRIP_PROPERTY_ESTIMATED_PAYMENT_TEXT]: "This is the estimated total monthly payment for this home, including the mortgage, taxes, and insurance. If the box is green, the payment is within your target limit (Buyer Investment Profile - BIP). If the box is red, the payment exceeds your limit. You can adjust your limits or explore other financing options",
    [TEXTS.TRIP_PROPERTY_CASH_NEEDED_TEXT]: "This is the estimated total cash needed to purchase this home, including the down payment, closing costs, and other fees. A green box means this amount is within your available funds limit (BIP). A red box means the cash needed exceeds your limit. You can adjust your available funds or negotiate terms to fit your budget",
    [TEXTS.TRIP_MENU_FAVORITES_TEXT]: "View and compare the properties you've saved. You can also see the saved searches to execute them whenever you want",
    [TEXTS.TRIP_MENU_PROFILE_TEXT]: "Manage your Buyer Investment Profile (BIP), and edit payment and cash limits",
    [TEXTS.TRIP_INVESTMENT_PROFILE_TEXT]: "These are the maximum monthly payment and total funds available that you've set. You can edit them and save the changes here",
    [TEXTS.TRIP_IMPORT_PROPERTIES_TEXT]: "Import properties from Zillow or Realtor.com by pasting the URL from the property listing page here. Easily add more homes to compare side-by-side",
    [TEXTS.TRIP_COMPARE_PROPERTIES_TEXT]: "Select up to five properties to compare side-by-side physical features and property financials. Tap anywhere on the property card to select or de-select",
    [TEXTS.TRIP_OFFER_PRO_TEXT]: "You can adjust the following values ​​if you want to qualify for payment and cash",
    [TEXTS.TRIP_SEARCH_PRO_LOANS_TEXT]: "Click here to compare FHA and Conventional loans. Adjust your offer price and down payment to see the impact on your financials",
    [TEXTS.TRIP_SEARCH_PRO_OFFER_PRICE_TEXT]: "Adjust the offer price by increasing or decreasing a percentage of the listing price. Padzilly applies this adjustment to all homes in your search and recalculates your estimated payments and total cash needed based on this adjustment",
    [TEXTS.TRIP_SEARCH_PRO_SELLER_TEXT]: "Use this slider to apply a seller rebate or contribution, reducing your total cash needed and monthly payment. Applies to all homes in your search area and will allow the amount according to your loan program limits.",
    [TEXTS.TRIP_SEARCH_PRO_SELLER_RED_TEXT]: "You must have an uploaded approval to use this feature",
    [TEXTS.TRIP_SEARCH_PRO_DOWN_PAYMENT_TEXT]: "Increase your down payment to lower your monthly payment and adjust your search results accordingly. This applies to all listings in your search",
    [TEXTS.TRIP_LOAN_ORIGINATOR_TEXT]: "Click here to invite your lender to upload your loan approval or pre-qualification and unlock all the Padzilly features, including the seller rebate/contribution",
    //ADD LOAN(BUYER)
    [TEXTS.TRUE_PDF_TEXT]: 'This is the type of file you need to upload so Padzilly can read it. A searchable PDF is created by choosing the "SAVE TO PDF" option from within your loan origination software',
    [TEXTS.IMAGE_PDF_TEXT]: 'Padzilly cannot read this type of PDF. It is not legible by software until it is converted to a searchable format. Image-only PDFs are usually created by choosing the "PRINT TO PDF" option and are not suitable to upload',
    [TEXTS.WARNING_TEXT]: "Primary borrower information such as name and contact details cannot be modified once the loan has been created",
    [TEXTS.LOAN_FORM_TEXT]: "This section should include realtor credits or DPA funds for programs WITHOUT a monthly payment. Seller credit should NOT be included",
    [TEXTS.COMPLETE_BYUER_INFO_TEXT]: "Please fix the buyer info errors and complete all the required data before saving the loan",
    [TEXTS.COMPLETE_CALCULATIONS_INFO_TEXT]: "Please fix the calculation errors and complete all the required data before saving the loan",
}