import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PercentIcon from '@mui/icons-material/Percent';
import { primaryTheme } from '../themes/themes';
import { ThemeProvider } from '@mui/material/styles';

export default function BasePercent({
    value,
    onInputChange,
    onBlur,
    readOnly,
    variant,
    style,
    placeholder,
    label,
    required,
    error,
    disabled
}) {

    const [val, setVal] = useState(value ?? '0');

    useEffect(() => {
        setVal(value);
    }, [value])

    const handleChange = (event) => {
        let valueP = event.target.value;

        const regex = /^-?[0-9]*\.?[0-9]{0,2}$/;

        if (valueP === '-') {
            setVal(valueP);
        } else if (regex.test(valueP)) {
            setVal(valueP);
            if (onInputChange) {
                onInputChange(parseFloat(valueP) || 0);
            }
        }
    };

    const handleBlur = () => {
        if (val === '-' || val === "" || val === "-0") {
            setVal('0');
            if (onInputChange) {
                onInputChange(0);
            }
        }
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <ThemeProvider theme={primaryTheme}>
            <TextField
                label={label}
                type="text"
                color='primary'
                error={error ? true : false}
                required={required}
                placeholder={placeholder ?? null}
                autoComplete='off'
                size="small"
                style={style ?? null}
                variant={variant ?? "standard"}
                helperText={error || ' '}
                value={val}
                onChange={handleChange}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton>
                            <PercentIcon fontSize='small' />
                        </IconButton>
                    </InputAdornment>,
                    readOnly: readOnly ?? false,
                }}
                onBlur={handleBlur}
                FormHelperTextProps={{
                    style: { minHeight: '5px' },
                }}
                disabled={disabled}
            />
        </ThemeProvider>
    );
}