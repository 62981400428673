import { Box } from "@mui/material";
import SearchLocation from "../Search/SearchLocation";
import SearchPro from "../../Dialogs/SearchPro";

export default function SearchTopBar() {

    return (
        <Box sx={{
            width: "100%",
            padding: "10px",
            background: "white",
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1,
            borderBottom: "solid 1px #8080807d",
        }}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
            }}>
                <SearchLocation />
                &nbsp;
                <SearchPro onClose={() => { }} />
            </Box>
        </Box>
    );
}