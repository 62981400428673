import {
    ADDED_TO_FAV_LABEL,
    CONV_DOWN_PAYMENT_MESSAGE_LABEL,
    FHA_DOWN_PAYMENT_MESSAGE_LABEL,
    IS_REQUIRED_ERROR_LABEL
} from "../i18n/i18nLabel";
import {
    FIRST_NAME_ERROR_TEXT,
    FULL_NAME_ERROR_TEXT,
    INVALID_EMAIL_ERROR_TEXT,
    LAST_NAME_ERROR_TEXT,
    MLO_SAME_EMAIL_ERROR_TEXT,
    MLO_WAS_REMOVED_ERROR_TEXT,
    MORTGAGE_PAYMENT_ERROR_TEXT,
    MUST_BE_GREATER_THAN_TEXT,
    NO_MLO_FOUND_ERROR_TEXT,
    ONLY_LETTERS_ERROR_TEXT,
    PASSWORDS_MUST_MATCH_ERROR_TEXT,
    VALID_URL_TO_IMPORT_ERROR_TEXT,
    MLO_ALREADY_INVITED,
    COMPLETE_BYUER_INFO_TEXT,
    COMPLETE_CALCULATIONS_INFO_TEXT,
    CANT_INVITE_PADZILLY_MLO_TEXT,
} from "../i18n/i18nText";

export const IS_REQUIRED = IS_REQUIRED_ERROR_LABEL;
export const IS_GREATER_THAN_CERO = MUST_BE_GREATER_THAN_TEXT;
export const FULL_NAME_ERROR = FULL_NAME_ERROR_TEXT;
export const EMAIL_ERROR = INVALID_EMAIL_ERROR_TEXT;
export const FIRST_NAME_ERROR = FIRST_NAME_ERROR_TEXT;
export const LAST_NAME_ERROR = LAST_NAME_ERROR_TEXT;
export const MORTAGE_PAYMENT_ERROR = MORTGAGE_PAYMENT_ERROR_TEXT;
export const ONLY_LETTERS = ONLY_LETTERS_ERROR_TEXT;
export const NO_MLO_ERROR = NO_MLO_FOUND_ERROR_TEXT;
export const MLO_ALREADY_INVITED_ERROR = MLO_ALREADY_INVITED;
export const MLO_SAME_EMAIL_ERROR = MLO_SAME_EMAIL_ERROR_TEXT;
export const MLO_REMOVED_MESSAGE = MLO_WAS_REMOVED_ERROR_TEXT;
export const SAVE_AS_FAVORITE_MESSAGE = ADDED_TO_FAV_LABEL;
export const VALID_URL_TO_IMPORT = VALID_URL_TO_IMPORT_ERROR_TEXT;
export const PASSWORDS_MUST_MATCH_MESSAGE = PASSWORDS_MUST_MATCH_ERROR_TEXT;
export const CONV_DOWN_PAYMENT_MESSAGE = CONV_DOWN_PAYMENT_MESSAGE_LABEL;
export const FHA_DOWN_PAYMENT_MESSAGE = FHA_DOWN_PAYMENT_MESSAGE_LABEL;
export const COMPLETE_BUYER_INFO_MESSAGE = COMPLETE_BYUER_INFO_TEXT;
export const COMPLETE_CALC_INFO_MESSAGE = COMPLETE_CALCULATIONS_INFO_TEXT;
export const CANT_INVITE_PADZILLY_MLO = CANT_INVITE_PADZILLY_MLO_TEXT;