import {
    Skeleton,
    Card,
    CardMedia,
    CardContent
} from "@mui/material";

export default function PropertyCardSkeleton() {
    return (
        <Card sx={{ minWidth: 310 }}>
            <CardMedia
                sx={{ height: 170 }}
            >
                <Skeleton variant="rectangular" style={{ height: "100%", width: "100%" }} />
            </CardMedia>
            <CardContent sx={{ height: "135px" }}>
                <Skeleton variant="rectangular" style={{ height: "20px", width: "100%" }} />
                <Skeleton variant="rectangular" style={{ height: "20px", width: "100%", marginTop: "10px" }} />
                <Skeleton variant="rectangular" style={{ height: "20px", width: "100%", marginTop: "10px" }} />
            </CardContent>
        </Card>
    );
}