export const GET_INITIAL_STATE = "GET_INITIAL_STATE";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_TOKEN = "GET_PROFILE_TOKEN";
export const GET_PROFILE_ID = "GET_PROFILE_ID";
export const GET_PROFILE_FINISHED = "GET_PROFILE_FINISHED";
export const GET_LAST_SEARCH = "GET_LAST_SEARCH";
export const GET_LAST_INPUT_SEARCH = "GET_LAST_INPUT_SEARCH";
export const GET_FUNDS_LIMIT = "GET_FUNDS_LIMIT";
export const GET_PAYMENT_LIMIT = "GET_PAYMENT_LIMIT";
export const GET_BORROWER_INVESTMENT_PROFILE = "GET_BORROWER_INVESTMENT_PROFILE";
export const GET_INVESTMENT_PROFILE = "GET_INVESTMENT_PROFILE";
export const GET_LOAN_TYPE = "GET_LOAN_TYPE";
export const GET_LAST_SEARCH_RESULTS = "GET_LAST_SEARCH_RESULTS";
export const GET_STORE_COMPLETE = "GET_STORE_COMPLETE";
export const GET_PROFILE_ROLE = "GET_PROFILE_ROLE";
export const GET_PROFILE_VERIFIED = "GET_PROFILE_VERIFIED";
export const GET_PROFILE_EMAIL = "GET_PROFILE_EMAIL";
export const GET_MLO_ID = "GET_MLO_ID";
export const GET_MLO_PROFILE = "GET_MLO_PROFILE";
export const GET_NO_MLO = "GET_NO_MLO";
export const GET_MLO_PASSED = "GET_MLO_PASSED";
export const GET_EXISTENT_LOAN = "GET_EXISTENT_LOAN";
export const GET_INVESTMENT_PROFILE_LOAN = "GET_INVESTMENT_PROFILE_LOAN";
export const GET_LOAN = "GET_LOAN";
export const GET_FAVORITES = "GET_FAVORITES";
export const GET_ORDER_BY = "GET_ORDER_BY";
export const GET_PAGE_FAVORITES = "GET_PAGE_FAVORITES";
export const GET_LAST_FAVORITE_RESULT = "GET_LAST_FAVORITE_RESULT";
export const GET_OFFER_PRO_VALUES = "GET_OFFER_PRO_VALUES";
export const GET_USER_ID_OTP = "GET_USER_ID_OTP";
export const GET_LOCATION = "GET_LOCATION";
export const GET_NEAR_PROPERTIES = "GET_NEAR_PROPERTIES";
export const GET_PAGE = "GET_PAGE";
export const GET_LAST_SEARCHED_PROPERTIES = "GET_LAST_SEARCHED_PROPERTIES";
export const GET_LANDING_PAGE_ELEMENTS = "GET_LANDING_PAGE_ELEMENTS";
export const GET_LANGUAGE = "GET_LANGUAGE";
export const GET_CONVENTIONAL_DOWN_PAYMENT = "GET_CONVENTIONAL_DOWN_PAYMENT";
export const GET_FHA_DOWN_PAYMENT = "GET_FHA_DOWN_PAYMENT";
export const GET_SEARCH_PRO_DOWN_PAYMENT = "GET_SEARCH_PRO_DOWN_PAYMENT";
export const GET_SEARCH_PREFERENCE = "GET_SEARCH_PREFERENCE";
export const GET_OFFER_PRICE_CONVENTIONAL = "GET_OFFER_PRICE_CONVENTIONAL";
export const GET_OFFER_PRICE_FHA = "GET_OFFER_PRICE_FHA";
export const GET_SELLER_REBATE_CONVENTIONAL = "GET_SELLER_REBATE_CONVENTIONAL";
export const GET_SELLER_REBATE_FHA = "GET_SELLER_REBATE_FHA";
export const GET_SEARCH_SAVED = "GET_SEARCH_SAVED";