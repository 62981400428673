import React, {
    createContext,
    useReducer,
    useContext,
    useEffect
} from 'react';
import { GETTERS } from './getters';
import * as GETTER_NAMES from '../../constants/store/getters';
import * as functions from './functions';
import {
    BORROWER_ROLE,
    CREATE_ACCOUNT,
    DEFAULT_CONVENTIONAL,
    DEFAULT_FHA,
    GUEST_ROLE,
    LOGIN_ACCOUNT,
} from '../../constants/constants';
import BaseAlert from '../../components/BaseComponents/BaseAlert';
import { useError } from '../handleError';
import {
    getBoolean,
    handleError
} from '../../utils/functions';
import { useTranslation } from 'react-i18next';

const reducer = (state, action) => {
    switch (action.action) {
        case GETTER_NAMES.GET_INITIAL_STATE:
            if (action.value === null) {
                return {};
            }
            return { ...state, ...action.value };
        default:
            return { ...state, [action.action]: action.value };
    }
};

const StoreContext = createContext(undefined);

export const StoreProvider = ({ children }) => {

    const { error, setErrorMessage, clearError } = useError();

    const [state, dispatch] = useReducer(reducer, GETTERS);

    const { i18n } = useTranslation();

    useEffect(() => {
        const fetchstate = async () => {
            for (const key in state) {
                if (Object.prototype.hasOwnProperty.call(state, key)) {
                    try {
                        switch (key) {
                            case GETTER_NAMES.GET_LANGUAGE:
                                state[key] = i18n.language;
                                break;
                            case GETTER_NAMES.GET_LOCATION:
                                state[key] = await functions.getLocation();
                                break;
                            case GETTER_NAMES.GET_PROFILE:
                                if (state[GETTER_NAMES.GET_PROFILE_ID]) {
                                    const user = await functions.getProfile(state[GETTER_NAMES.GET_PROFILE_ID]);
                                    if (user) {
                                        state[key] = user;
                                    } else {
                                        state[key] = null;
                                    }
                                } else {
                                    state[key] = null;
                                }
                                break;
                            case GETTER_NAMES.GET_PROFILE_TOKEN:
                                state[key] = state[GETTER_NAMES.GET_PROFILE]?.token ?? null;
                                break;
                            case GETTER_NAMES.GET_PROFILE_ROLE:
                                state[key] = state[GETTER_NAMES.GET_PROFILE]?.role ?? localStorage.getItem("profile_role");
                                state[key] ? localStorage.setItem("profile_role", state[key]) : null;
                                break;
                            case GETTER_NAMES.GET_PROFILE_EMAIL:
                                state[key] = state[GETTER_NAMES.GET_PROFILE]?.email ?? null;
                                break;
                            case GETTER_NAMES.GET_PROFILE_VERIFIED:
                                state[key] = (state[GETTER_NAMES.GET_PROFILE]?.hasConfirmedOTP && state[GETTER_NAMES.GET_PROFILE]?.role !== GUEST_ROLE) ? true : false;
                                break;
                            case GETTER_NAMES.GET_LOAN:
                                if (state[GETTER_NAMES.GET_PROFILE_TOKEN] && state[GETTER_NAMES.GET_PROFILE_ROLE] === BORROWER_ROLE) {
                                    state[key] = await functions.getLoanByEmail(state[GETTER_NAMES.GET_PROFILE_TOKEN]) ?? null;
                                } else {
                                    state[key] = null;
                                }
                                break;
                            case GETTER_NAMES.GET_INVESTMENT_PROFILE:
                                if ((state[GETTER_NAMES.GET_PROFILE_TOKEN] && state[GETTER_NAMES.GET_BORROWER_INVESTMENT_PROFILE])
                                    || (state[GETTER_NAMES.GET_PROFILE_TOKEN] && state[GETTER_NAMES.GET_PROFILE_FINISHED])
                                ) {
                                    state[key] = await functions.getInvestmentProfile(state[GETTER_NAMES.GET_PROFILE_TOKEN]) ?? null;
                                } else {
                                    state[key] = null;
                                }
                                break;
                            case GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN:
                                state[key] = state[GETTER_NAMES.GET_INVESTMENT_PROFILE]?.loan ?? null;
                                break;
                            case GETTER_NAMES.GET_SEARCH_PRO_DOWN_PAYMENT:
                                if (!getBoolean(localStorage.getItem("loan_info")) && state[GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN]) {
                                    localStorage.setItem("search_pro_down_payment", false);
                                    localStorage.setItem("loan_info", true);
                                }
                                if (!getBoolean(localStorage.getItem("search_pro_down_payment")) && state[GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN]) {
                                    state[key] = false;
                                    localStorage.setItem("search_pro_down_payment", false);
                                } else {
                                    localStorage.setItem("search_pro_down_payment", true);
                                    state[key] = true;
                                }
                                break;
                            case GETTER_NAMES.GET_PAYMENT_LIMIT:
                                state[key] = state[GETTER_NAMES.GET_INVESTMENT_PROFILE]?.paymentLimit ?? null;
                                break;
                            case GETTER_NAMES.GET_FUNDS_LIMIT:
                                state[key] = state[GETTER_NAMES.GET_INVESTMENT_PROFILE]?.investmentAmount ?? null;
                                break;
                            case GETTER_NAMES.GET_CONVENTIONAL_DOWN_PAYMENT:
                                if (!localStorage.getItem("down_payment_conventional")) {
                                    localStorage.setItem("down_payment_conventional", DEFAULT_CONVENTIONAL);
                                }
                                localStorage.setItem("down_payment_conventional", !state[GETTER_NAMES.GET_SEARCH_PRO_DOWN_PAYMENT] ? state[GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc / 100 : localStorage.getItem("down_payment_conventional"));
                                state[key] = localStorage.getItem("down_payment_conventional");
                                break;
                            case GETTER_NAMES.GET_FHA_DOWN_PAYMENT:
                                if (!localStorage.getItem("down_payment_fha")) {
                                    localStorage.setItem("down_payment_fha", DEFAULT_FHA);
                                }
                                localStorage.setItem("down_payment_fha", !state[GETTER_NAMES.GET_SEARCH_PRO_DOWN_PAYMENT] ? state[GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc / 100 : localStorage.getItem("down_payment_fha"));
                                state[key] = localStorage.getItem("down_payment_fha");
                                break;
                            case GETTER_NAMES.GET_OFFER_PRICE_CONVENTIONAL:
                                if (!localStorage.getItem("offer_price_conventional")) {
                                    localStorage.setItem("offer_price_conventional", 0);
                                }
                                state[key] = Number(localStorage.getItem("offer_price_conventional"));
                                break;
                            case GETTER_NAMES.GET_OFFER_PRICE_FHA:
                                if (!localStorage.getItem("offer_price_fha")) {
                                    localStorage.setItem("offer_price_fha", 0);
                                }
                                state[key] = Number(localStorage.getItem("offer_price_fha"));
                                break;
                            case GETTER_NAMES.GET_SELLER_REBATE_CONVENTIONAL:
                                if (!localStorage.getItem("seller_rebate_conventional")) {
                                    localStorage.setItem("seller_rebate_conventional", 0);
                                }
                                state[key] = Number(localStorage.getItem("seller_rebate_conventional"));
                                break;
                            case GETTER_NAMES.GET_SELLER_REBATE_FHA:
                                if (!localStorage.getItem("seller_rebate_fha")) {
                                    localStorage.setItem("seller_rebate_fha", 0);
                                }
                                state[key] = Number(localStorage.getItem("seller_rebate_fha"));
                                break;
                            case GETTER_NAMES.GET_LAST_SEARCH_RESULTS:
                                state[key] = localStorage.getItem("last_search_results")
                                    ? await functions.setLastResults(JSON.parse(localStorage.getItem("last_search_results")), state[GETTER_NAMES.GET_FAVORITES])
                                    : [];
                                break;
                            case GETTER_NAMES.GET_MLO_ID:
                                if (state[GETTER_NAMES.GET_INVESTMENT_PROFILE]) {
                                    state[key] = state[GETTER_NAMES.GET_INVESTMENT_PROFILE]?.loanOriginatorId ?? null;
                                }
                                break;
                            case GETTER_NAMES.GET_MLO_PROFILE:
                                if (state[GETTER_NAMES.GET_MLO_ID] && state[GETTER_NAMES.GET_PROFILE_TOKEN]) {
                                    state[key] = await functions.getMloProfile(state[GETTER_NAMES.GET_MLO_ID], state[GETTER_NAMES.GET_PROFILE_TOKEN]) ?? null;
                                } else {
                                    state[key] = null;
                                }
                                break;
                            case GETTER_NAMES.GET_FAVORITES:
                                if (state[GETTER_NAMES.GET_PROFILE_ID]
                                    && state[GETTER_NAMES.GET_PROFILE_TOKEN]
                                    && state[GETTER_NAMES.GET_PROFILE_ROLE] === BORROWER_ROLE) {
                                    state[key] = await functions.getFavorites(state[GETTER_NAMES.GET_PAGE_FAVORITES], 20, state[GETTER_NAMES.GET_PROFILE_ID], state[GETTER_NAMES.GET_PROFILE_TOKEN]) ?? [];
                                } else {
                                    state[key] = [];
                                }
                                break;
                            case GETTER_NAMES.GET_SEARCH_SAVED:
                                if (state[GETTER_NAMES.GET_PROFILE_ROLE] === BORROWER_ROLE) {
                                    state[key] = await functions.getSavedSearches(state[GETTER_NAMES.GET_PROFILE_EMAIL], state[GETTER_NAMES.GET_PROFILE_TOKEN]) ?? [];
                                } else {
                                    state[key] = [];
                                }
                                break;
                            case GETTER_NAMES.GET_LAST_FAVORITE_RESULT:
                                state[key] = state[GETTER_NAMES.GET_FAVORITES]?.length < 20 ? true : false;
                                break;
                            case GETTER_NAMES.GET_STORE_COMPLETE:
                                state[key] = true;
                                break;
                            default:
                                break;
                        }
                    } catch (e) {
                        if (handleError(e) === "Account not found") {
                            localStorage.clear();
                            window.location.reload();
                        }
                        setErrorMessage(handleError());
                    }
                }
            }
            dispatch({ action: GETTER_NAMES.GET_INITIAL_STATE, value: state });
        };

        if (localStorage.getItem("profile_finished")
            || localStorage.getItem("profile_id")
            || localStorage.getItem("user_id_otp")
            || localStorage.getItem("page") === "/"
            || localStorage.getItem("page") === LOGIN_ACCOUNT
            || localStorage.getItem("page") === CREATE_ACCOUNT) {
            fetchstate();
        }
    }, []);

    return (
        <StoreContext.Provider value={{ state, dispatch }}>
            <BaseAlert alert={error} onClose={() => { clearError(); }} />
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error('useStore must be used inside a StoreProvider');
    }
    return context;
};