import { Box, Container } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    TITLES_COLOR,
} from "../../constants/constants";
import { useTranslation } from 'react-i18next';
import {
    AND_LABEL,
    GAIN_THE_UPPER_HAND_LABEL,
    INFO_TITLE_ONE_LABEL,
    LENDER_APPROVAL_LABEL,
    LINK_LABEL,
    LOAN_LINK_LABEL,
    OFFER_LIKE_A_PRO_LABEL,
    OFFER_PRO_LANDING_LABEL,
    PRO_LABEL,
    SEARCH_PRO_LANDING_LABEL,
} from "../../i18n/i18nLabel";
import {
    INFO_ONE_POINT_ONE_TEXT,
    INFO_ONE_POINT_THREE_TEXT,
    INFO_ONE_POINT_TWO_TEXT,
    INFO_TEXT_ONE_TEXT,
    INFO_THREE_POINT_FOUR_TEXT,
    INFO_THREE_POINT_ONE_TEXT,
    INFO_THREE_POINT_THREE_TEXT,
    INFO_THREE_POINT_TWO_TEXT,
    INFO_TWO_POINT_ONE_TEXT,
    INFO_TWO_POINT_THREE_TEXT,
    INFO_TWO_POINT_TWO_TEXT,
    LOAN_APPROVAL_TEXT,
    OFFER_LIKE_A_PRO_TEXT,
} from "../../i18n/i18nText";
import CheckIcon from '@mui/icons-material/Check';
import BaseVideo from "../BaseComponents/BaseVideo";

export default function LandingPageInfo() {

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const cards = [
        {
            image: <BaseVideo
                src="https://player.vimeo.com/video/1021048326"
            />,
            title: t(INFO_TITLE_ONE_LABEL),
            text: t(INFO_TEXT_ONE_TEXT),
            points: [
                t(INFO_ONE_POINT_ONE_TEXT),
                t(INFO_ONE_POINT_TWO_TEXT),
                t(INFO_ONE_POINT_THREE_TEXT)
            ],
        },
        {
            image: <BaseVideo
                src="https://player.vimeo.com/video/1021050594"
            />,
            title: t(OFFER_LIKE_A_PRO_LABEL),
            text: t(OFFER_LIKE_A_PRO_TEXT),
            points: [
                t(INFO_TWO_POINT_ONE_TEXT),
                t(INFO_TWO_POINT_TWO_TEXT),
                t(INFO_TWO_POINT_THREE_TEXT)
            ],
        },
        {
            image: <BaseVideo
                src="https://player.vimeo.com/video/1021050623"
            />,
            title: t(LENDER_APPROVAL_LABEL),
            text: t(LOAN_APPROVAL_TEXT),
            points: [
                t(INFO_THREE_POINT_ONE_TEXT),
                t(INFO_THREE_POINT_TWO_TEXT),
                t(INFO_THREE_POINT_THREE_TEXT),
                t(INFO_THREE_POINT_FOUR_TEXT)
            ],
        }
    ];

    return (
        <Box
            sx={{
                background: "white",
            }}
        >
            <Container
                sx={{
                    background: "white",
                    padding: "5%"
                }}
            >
                <p
                    style={{
                        textAlign: "center",
                        fontSize: "2rem",
                        fontWeight: "bold",
                        margin: "0px",
                        color: TITLES_COLOR
                    }}
                >
                    {t(GAIN_THE_UPPER_HAND_LABEL)}
                </p>
                <p
                    style={{
                        textAlign: "center",
                        fontSize: "2rem",
                        fontWeight: "bold",
                        marginTop: "0px"
                    }}
                >
                    <span style={{ color: PRIMARY_COLOR }}>{t(SEARCH_PRO_LANDING_LABEL)}</span>
                    <span style={{ color: SECONDARY_COLOR }}>{t(PRO_LABEL)}</span>
                    <span>, </span>
                    <span style={{ color: PRIMARY_COLOR }}>{t(OFFER_PRO_LANDING_LABEL)}</span>
                    <span style={{ color: SECONDARY_COLOR }}>{t(PRO_LABEL)}</span>
                    <span> {t(AND_LABEL)} </span>
                    <span style={{ color: PRIMARY_COLOR }}>{t(LOAN_LINK_LABEL)}</span>
                    <span style={{ color: SECONDARY_COLOR }}>{t(LINK_LABEL)}</span>
                </p>
                {cards.map((card, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: isSmOrSmaller ? "column" : (index % 2) === 0 ? "row" : "row-reverse",
                            marginBottom: index === (cards.length - 1) ? "0px" : "50px"
                        }}
                    >
                        <Box
                            sx={{
                                width: isSmOrSmaller ? "100%" : "50%",
                                display: "flex",
                                justifyContent: isSmOrSmaller ? "center" : (index % 2) === 0 ? "end" : "start"
                            }}
                        >
                            {card.image}
                        </Box>
                        <Box
                            sx={{
                                width: isSmOrSmaller ? "100%" : "50%",
                                padding: "20px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: isSmOrSmaller ? "center" : "start"
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "0px",
                                    fontWeight: "bold",
                                    fontSize: "1.5rem",
                                    textAlign: isSmOrSmaller ? "center" : "start",
                                    marginBottom: "5px",
                                    color: TITLES_COLOR
                                }}
                            >
                                {card.title}
                            </p>
                            <p
                                style={{
                                    textAlign: "start",
                                    margin: "0px",
                                    fontSize: "1rem",
                                    width: "100%",
                                    marginBottom: "10px",
                                    color: "black"
                                }}
                            >
                                {card.text}
                            </p>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start"
                            }}>
                                {
                                    card.points.map((item) => (
                                        <Box sx={{ display: "flex" }} key={item}>
                                            <CheckIcon
                                                style={{ color: PRIMARY_COLOR }}
                                                fontSize="medium"
                                            />
                                            <span
                                                style={{
                                                    fontSize: ".9rem",
                                                    marginLeft: "5px"
                                                }}>
                                                {item}
                                            </span>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>
                ))
                }
            </Container >
        </Box>
    )
}