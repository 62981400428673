import { CALCULATION_OFFER_PRO_VALUES, DESC, LIST } from "../../constants/constants";
import * as GETTER_NAMES from "../../constants/store/getters";
import { getBoolean } from "../../utils/functions";

export const GETTERS = {
    [GETTER_NAMES.GET_LANGUAGE]: null,
    [GETTER_NAMES.GET_PAGE]: localStorage.getItem("page"),
    [GETTER_NAMES.GET_LANDING_PAGE_ELEMENTS]: ["NO ELEMENTS"],
    [GETTER_NAMES.GET_LOCATION]: localStorage.getItem("address_info") ? JSON.parse(localStorage.getItem("address_info")) : null,
    [GETTER_NAMES.GET_LAST_SEARCH]: localStorage.getItem("last_search") ? JSON.parse(localStorage.getItem("last_search")) : {},
    [GETTER_NAMES.GET_PROFILE_ID]: localStorage.getItem("profile_id"),
    [GETTER_NAMES.GET_PROFILE]: null,
    [GETTER_NAMES.GET_PROFILE_TOKEN]: null,
    [GETTER_NAMES.GET_PROFILE_ROLE]: null,
    [GETTER_NAMES.GET_PROFILE_EMAIL]: null,
    [GETTER_NAMES.GET_PROFILE_VERIFIED]: null,
    [GETTER_NAMES.GET_PROFILE_FINISHED]: getBoolean(localStorage.getItem("profile_finished")),
    [GETTER_NAMES.GET_BORROWER_INVESTMENT_PROFILE]: getBoolean(localStorage.getItem("borrower_investment_profile")),
    [GETTER_NAMES.GET_INVESTMENT_PROFILE]: null,
    [GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN]: null,
    [GETTER_NAMES.GET_LOAN]: null,
    [GETTER_NAMES.GET_FUNDS_LIMIT]: null,
    [GETTER_NAMES.GET_PAYMENT_LIMIT]: null,
    [GETTER_NAMES.GET_LOAN_TYPE]: localStorage.getItem("loan_type"),
    [GETTER_NAMES.GET_SEARCH_PRO_DOWN_PAYMENT]: null,
    [GETTER_NAMES.GET_CONVENTIONAL_DOWN_PAYMENT]: null,
    [GETTER_NAMES.GET_FHA_DOWN_PAYMENT]: null,
    [GETTER_NAMES.GET_OFFER_PRICE_CONVENTIONAL]: null,
    [GETTER_NAMES.GET_OFFER_PRICE_FHA]: null,
    [GETTER_NAMES.GET_SELLER_REBATE_CONVENTIONAL]: null,
    [GETTER_NAMES.GET_SELLER_REBATE_FHA]: null,
    [GETTER_NAMES.GET_PAGE_FAVORITES]: 0,
    [GETTER_NAMES.GET_FAVORITES]: [],
    [GETTER_NAMES.GET_LAST_FAVORITE_RESULT]: null,
    [GETTER_NAMES.GET_LAST_INPUT_SEARCH]: localStorage.getItem("last_input_search") ? JSON.parse(localStorage.getItem("last_input_search")) : "",
    [GETTER_NAMES.GET_LAST_SEARCH_RESULTS]: [],
    [GETTER_NAMES.GET_SEARCH_SAVED]: [],
    [GETTER_NAMES.GET_MLO_ID]: null,
    [GETTER_NAMES.GET_MLO_PROFILE]: null,
    [GETTER_NAMES.GET_NO_MLO]: localStorage.getItem("no_mlo"),
    [GETTER_NAMES.GET_MLO_PASSED]: localStorage.getItem("mlo_passed"),
    [GETTER_NAMES.GET_EXISTENT_LOAN]: localStorage.getItem("loan_exist") ? JSON.parse(localStorage.getItem("loan_exist")) : null,
    [GETTER_NAMES.GET_ORDER_BY]: { orderBy: "", direction: DESC },
    [GETTER_NAMES.GET_OFFER_PRO_VALUES]: CALCULATION_OFFER_PRO_VALUES,
    [GETTER_NAMES.GET_USER_ID_OTP]: localStorage.getItem("user_id_otp"),
    [GETTER_NAMES.GET_SEARCH_PREFERENCE]: LIST,
    [GETTER_NAMES.GET_STORE_COMPLETE]: null, //always null, only can change on store.js
}