import DialogActions from '@mui/material/DialogActions';

export default function BaseDialogFooter({ actions, className, children }) {
    return (
        <DialogActions
            style={{ padding: "14px", borderTop: "1px solid rgba(0, 0, 0, .05)" }}
            className={actions ? "dialogFooter" : className ?? ""}
        >
            {children}
        </DialogActions>
    );
}